import { Input, notification, Table } from "antd";
import { Key, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { AXIOS_CANCEL_MSG, BaseQuote, QuoteFilter,  SortDirection } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {RowSelectMethod, } from "antd/es/table/interface";
import { debounce } from "lodash";
import axios, {CancelTokenSource} from "axios";
import { SalesTeamFilter } from "../components/SelectSalesTeamButton";
import { BaseQuoteDescription } from "../pages/quotes";

type BaseQuoteSort = SorterResult<BaseQuote> | SorterResult<BaseQuote>[]
type SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface RowSelection<T> { 
  selections: T[], 
  type:RowSelectMethod
}
export type QuoteSelection = RowSelection<number>;

const DEFAULT_PAGE_SIZE = 5;

const SelectQuoteBySalesTeamList = (props:{
  primarySalesId?: string | undefined
  dealerId?: string | undefined
  onChange?:(s:QuoteSelection) => void
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [quoteList, quoteListAsync] = useAsyncState<BaseQuote[]>([]);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [selected, setSelected] = useState<QuoteSelection>();
  const [filter, setFilter] = useState<QuoteFilter>();
  const [sort, setSort] = useState<SortResult<BaseQuote>>({
    columnKey: "quoteId",
    order: "descend"
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
    showLessItems: true
  });
  useEffect(() => {
    loadQuotes(quoteListAsync, pagination, filter, sort);
  }, [pagination.pageSize, pagination.current, filter, sort, props.dealerId, props.primarySalesId]);

  useEffect(() => {
    setFilter( { 
      ...filter, 
        dealerLst: props.dealerId ? [props.dealerId] : undefined,
        primarySalesId: props.primarySalesId
    });
  }, [props.primarySalesId, props.dealerId]);

  const loadQuotes = useCallback(debounce( async (quoteListAsync:AsyncState<BaseQuote[]>, pagination: TablePaginationConfig, filter: QuoteFilter | undefined, sorter:BaseQuoteSort) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "quoteId",
      direction: sorter.order == 'descend' ? 'desc' : 'asc' as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    quoteListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuotes({
        ...filter,
        includingArchived:false,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;
      quoteListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Quotes failed to load. " + errorMsg });
        quoteListAsync.setFail(e.message);
      }
    }
  }, 600), []);


  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: BaseQuoteSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const handleChangeSearch = (search:string) => {
    const nextFilter:SalesTeamFilter = {
      ...filter,
      search
    };
    setFilter(nextFilter);
    loadQuotes( quoteListAsync, pagination, nextFilter, sort );
  }

  const handleSelectRow = (selectedRowKeys: Key[], _selectedRows:BaseQuote[], info: { type:RowSelectMethod } ) => {

    const lst = selectedRowKeys as number[];
    const selected = {selections: lst, type:info.type};
    setSelected( selected );
    props.onChange?.( selected );
  }

  return <>
    <div style={{width: "100%"}}>
      <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" allowClear />
    </div>

    <style>
      {`
      .dialog-updateQuoteLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-updateQuoteLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-updateQuoteLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table 
      className="dialog-updateQuoteLst"
      columns={ [ 
        {
          title: "Quote",
          key: "quoteId",
          fixed: "left",
          defaultSortOrder: "descend",
          sorter: true,
          render: (q) => <BaseQuoteDescription quote={q} hideTruckDescription={true} />,
        },
        {
          title: "Salesperson",
          render: (q:BaseQuote) => q.owner?.name
        },
        {
          title: "BM Sales",
          render: (q:BaseQuote) => q.salesTeam?.sales?.map(u => u.name ).join(", ")
        },
        {
          title: "Engineers",
          render: (q:BaseQuote) => q.salesTeam?.engineers?.map(u => u.name ).join(", ")
        },
        {
          title: "Support",
          render: (q:BaseQuote) => q.salesTeam?.support?.map(u => u.name ).join(", ")
        },
        {
          title: "Viewers",
          render: (q:BaseQuote) => q.salesTeam?.viewers?.map(u => u.name ).join(", ")
        },
      ]}
      rowKey="id"
      size="small"
      pagination={pagination}
      onChange={tableOnChange}
      loading={quoteListAsync?.isLoading()}
      dataSource={quoteList}
      scroll={{ x: true }}
      rowSelection={{
        type:  "checkbox",
        onChange: handleSelectRow,
        hideSelectAll: false,
        selectedRowKeys: selected?.selections,
      }}
    />
  </>
}

export default SelectQuoteBySalesTeamList;
