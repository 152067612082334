import "../util/mobile-table.css";
import styles from "./approvals.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Button, Space, TablePaginationConfig, Card, Tooltip, Modal, Select, Checkbox, notification, Row, Col, Form, Collapse, Divider } from "antd";
import { ColumnType } from 'antd/es/table';
import { createContext, ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import { Approval, ApprovalFilter, ApprovalRequest, ApproverRole, AXIOS_CANCEL_MSG, BaseModel,  DEFAULT_THROTTLE,  PAGINATION_MAX_PAGE_SIZE, SortDirection, User } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {FilterValue, SorterResult} from "antd/lib/table/interface";
import {debounce, throttle} from "lodash";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import { LeftOutlined, InfoOutlined, PartitionOutlined } from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {useIntl} from "react-intl";
import UserMultiSelector, {UserMultiSelectorProps} from "../components/UserMultiSelector";
import axios, {CancelTokenSource} from "axios";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";
import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";
import { useUsersContext } from "../contexts/UsersContext";

type BaseApprovalSort = SorterResult<Approval> | SorterResult<Approval>[]
type TableOnChangeFn = (p: TablePaginationConfig, f: Record<string, FilterValue | null>, s: BaseApprovalSort) => void

const getProductionDateStr = (date?: Date | undefined ) => {
  const days = date ? dayjs(date).diff(dayjs(), 'day') : undefined
  return days !== undefined ? days + " days" : 'Not Available';
};

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SORT:BaseApprovalSort = [
  {
    columnKey: 'approvalTypeOrder',
    order: 'ascend'
  },
  {
    columnKey: 'quoteRevision.partNumberMajor',
    order: 'descend'
  },
  {
    columnKey: 'quoteRevision.summary.productionDate',
    order: 'ascend'
  },
  {
    columnKey: 'createdAt',
    order: 'ascend'
  },
];

interface ApprovalListContextType {
  approvalLstAsync?:AsyncState<Approval[]>
  loadQuoteApprovals?: () => void
}
const ApprovalListContext = createContext<ApprovalListContextType>({
});

const useApprovalListContext = () : ApprovalListContextType => {
    return useContext(ApprovalListContext);
}

// Data provider component
const ApprovalListContextProvider = (props:{ value:ApprovalListContextType, children: ReactElement | ReactElement[] }) => {

  const approvalLstContext = props.value;
  return <ApprovalListContext.Provider value={approvalLstContext}>{props.children}</ApprovalListContext.Provider>;
};


const Approvals = () => {
  const isMobile = useCheckMobileScreen();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const [_approvalLst, approvalLstAsync] = useAsyncState<Approval[]>();

  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("search", StringParam);
  const [actionedParam, setActionedParam] = useQueryParam<boolean | undefined | null>("actioned", BooleanParam);
  const [engineersParam, setEngineersParam] = useQueryParam<(string | null)[]|undefined|null>("engineers", ArrayParam);
  const [salesParam, setSalesParam] = useQueryParam<(string | null)[]|undefined|null>("sales", ArrayParam);
  const [requestedByParam, setRequestedByParam] = useQueryParam<(string | null)[]|undefined|null>("requestedBy", ArrayParam);
  const [approvalTypeParam, setApprovalTypeParam] = useQueryParam<(string | null)[]|undefined|null>("approvalType", ArrayParam);
  const [approverRoleParam, setApproverRoleParam] = useQueryParam<(string | null)[]|undefined|null>("approverRole", ArrayParam);
  const [modelsParam, setModelsParam] = useQueryParam<(string | null)[]|undefined|null>("models", ArrayParam);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [sort, setSort] = useState<BaseApprovalSort>(DEFAULT_SORT);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const defaultEngineerLst = ( configurator.isEngineering() && !configurator.isAdmin() ) && configurator.userInfo ? [configurator.userInfo.name] : [];
  const defaultSalesDeskRoleLst = ( configurator.isSalesDesk() && !configurator.isAdmin() ) && [ ApproverRole.SALES_DESK ];
  const [filter, setFilter] = useState<ApprovalFilter>({
    actioned: actionedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    approverRole: [approverRoleParam as (ApproverRole|null) || defaultSalesDeskRoleLst || []].flat(),
    approvalType: [approvalTypeParam as (ApprovalRequest|null) || []].flat(),
    engineers: [engineersParam as (string|null) || defaultEngineerLst ].flat(),
    sales: [salesParam as (string|null) || []].flat(),
    requestedBy: [requestedByParam as (string|null) || []].flat(),
    models: [modelsParam as (string|null) || []].flat(),
  });


  useEffect(() => {
    reloadQuoteApprovals();
    return () => loadQuoteApprovals.cancel();
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);

  const reloadQuoteApprovals = () => loadQuoteApprovals(approvalLstAsync, pagination, filter, sort);
  const loadQuoteApprovals = useCallback(throttle( async (approvalListAsync:AsyncState<Approval[]>,  pagination: TablePaginationConfig, filter: ApprovalFilter, sorter:BaseApprovalSort) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sort = ([sorter].flat() || DEFAULT_SORT).map( sorter => ({
      field: sorter.columnKey as string,
      direction: ( sorter.order === 'descend' ? 'desc' : 'asc') as SortDirection,
    }));

    approvalListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuoteApprovals({
          ...filter,
          page: (pagination.current || 1) - 1,
          size: pagination.pageSize || DEFAULT_PAGE_SIZE,
          sort,
        },
        cancelSource.token
      )
      cancelTokenSourceRef.current = undefined;

      approvalListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Quote approvals failed to load. " + errorMsg });
        approvalListAsync.setFail(e.message);
      }
    }
  }, DEFAULT_THROTTLE), []);


  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: BaseApprovalSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const onFilterChange = debounce( (_values: Record<string, any>, filter:ApprovalFilter) => {
    setPagination({ ...pagination, current: 1 });

    setSearchFilterParam(filter.search)
    setActionedParam    (filter.actioned)
    setEngineersParam   (filter.engineers)
    setSalesParam      (filter.sales)
    setRequestedByParam (filter.requestedBy)
    setApprovalTypeParam(filter.approvalType)
    setApproverRoleParam(filter.approverRole)
    setModelsParam(filter.models)

    setFilter(filter);
  }, 300);


  return <>
    <div className="site-layout-background">
      <Title level={2}>Approvals</Title>
      <Space direction="vertical" size="middle"
        style={{ width: "100%" }}
      >
        <FilterControls
          filter={filter}
          onFilterChange={onFilterChange} />

        <ApprovalListContextProvider value={{approvalLstAsync, loadQuoteApprovals: reloadQuoteApprovals}}>
        {isMobile ?
        (<>
          <MobileTable
          tableOnChange={tableOnChange}
          pagination={pagination}
          />
        </>) : 
        (<>
          <DesktopTable
            tableOnChange={tableOnChange}
            pagination={pagination}
          />
        </>)
        }
        </ApprovalListContextProvider>
      </Space>
    </div>
  </>;
};

const DesktopTable = (props: {
  tableOnChange: TableOnChangeFn;
  pagination: TablePaginationConfig;
}) => {
  const { tableOnChange, pagination } = props;

  const {approvalLstAsync, loadQuoteApprovals } = useApprovalListContext();
  const approvalList = approvalLstAsync?.val;


  const columns:ColumnType<Approval>[] = [
    {
      title: "Type Order",
      key: "approvalTypeOrder",
      dataIndex: "approvalTypeOrder",
      sorter: { multiple: 1},
      defaultSortOrder: "ascend",
      hidden: true,
    },
    {
      title: "Part Number",
      key: "quoteRevision.partNumberMajor",
      sorter: { multiple: 1},
      defaultSortOrder: "descend",
      hidden: true,
    },
    {
      title: "Quote Details",
      key: "revision.name",
      render: (q:Approval) => <ApprovalDescription approval={q} onChange={loadQuoteApprovals} />,
      width: "40rem",
      sorter: true,
    },
    {
      title: "Type",
      key: "approvalType",
      sorter: { multiple: 1},
      render: (q:Approval) => Utils.formatApprovalType(q.approvalType, q.quoteInfo.reservation),
    },
    {
      title: "Days till Production",
      key: "quoteRevision.summary.productionDate",
      sorter: { multiple: 2},
      defaultSortOrder: "ascend",
      render: (q:Approval) => (
        <span>{getProductionDateStr(q.quoteInfo.productionDate)}</span>
      ),
    },
    {
      title: "Approval Required By",
      key: "approverRole",
      sorter: true,
      render: (q:Approval) => Utils.snakeCaseToFirstLetterCapitalized(q.approverRole)
    },
    {
      title: "Requested By",
      dataIndex: "requestedBy",
      render: (user: User) => user?.name,
    },
    {
      title: "Requested On",
      key: "createdAt",
      defaultSortOrder: "ascend",
      sorter: { multiple: 1},
      render: (q:Approval) => (
        <span>{dayjs(q.createdAt).format("MMM Do YY, HH:mm")}</span>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={approvalLstAsync?.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={approvalList}
        columns={columns}
        rowKey="id"
        size="small"
      />
    </>
  );
}

const MobileTable = (props:{
  tableOnChange: TableOnChangeFn
  pagination:TablePaginationConfig
}) => {
  const { tableOnChange, pagination } = props;

  const {approvalLstAsync } = useApprovalListContext();
  const approvalList = approvalLstAsync?.val;

  const columns:ColumnType<Approval>[] = [
    {
      render: (_val, approval, _ndx) => {
        return <>
          <Link to={`/approvals/${approval.id}`} style={{color:"black"}}>
            <Card 
              hoverable={true}
              title={<>
                <div className={styles["approval-type"]}>{Utils.formatApprovalType(approval.approvalType, approval.quoteInfo.reservation)}</div>
              </>
              } 
            >
              <div className={styles["quote-name"]}>{approval.quoteInfo.name}</div>
              <div className={styles["quote-id"]}>({approval.quoteInfo.quoteId})</div>
              <div className="section">
                <div>Requested:</div>
                <div><span>{approval.requestedBy?.name}</span></div>
                <div><span>{dayjs(approval.createdAt).format("MMMM Do YYYY")}</span></div>
              </div>
            </Card>
          </Link>
        </>;
      },
    },
  ];

  return <Table
    loading={approvalLstAsync?.isLoading()}
    onChange={tableOnChange}
    pagination={pagination}
    dataSource={approvalList}
    columns={columns}
    rowKey="id"
    className="mobile-table"
  />
}

const FilterControls = (props: {
  filter?: ApprovalFilter
  onFilterChange: (_values: Record<string, any>, filter:ApprovalFilter) => void
}) => {
  const { filter } = props;

  const [filterForm] = useForm();
  const showAdvancedPanel = 
    filter?.approvalType?.length ||
    filter?.models?.length ||
    filter?.requestedBy?.length ||
    filter?.approverRole?.length ||
    filter?.sales?.length ||
    filter?.engineers?.length;

  const configurator = useContext(ConfiguratorContext);

  return <>
    <Form 
      initialValues={props.filter}
      form={filterForm} 
      onValuesChange={props.onFilterChange}
      layout="vertical"
    >
      <Row gutter={[16, 8]} align="middle">
        <Col span={18}>
          <Form.Item name="search" >
            <Input
              allowClear
              value={filter?.search}
              placeholder="Search by quote name, ID, and more."
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="actioned"
            valuePropName="checked"
          >
            <Checkbox>including actioned</Checkbox>
          </Form.Item>
        </Col>
      </Row>
        <Collapse
          style={{ width: '100%', marginTop: "-1rem" }} size="small"
          defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
          {...AdvancedSearchConfig}
          items={[{
            key: "advanced-search",
            label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
            forceRender: true,
            children: <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Row gutter={[16, 8]}>

                {(configurator.isAdmin() || configurator.isSalesDesk() || configurator.isEngineering() || configurator.isReleaseEngineering() ) && 
                <Col span={6}>
                  <Form.Item
                    name="approverRole"
                    label="Approver Role"
                  >
                    <ApproverRoleSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                }

                <Col span={6} >
                  <Form.Item
                    name="models"
                    label="Model"
                  >
                    <ModelSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={6} >
                  <Form.Item
                    name="approvalType"
                    label="Approval Type"
                  >
                    <ApprovalRequestSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="requestedBy"
                    label="Requested By"
                  >
                    <UserNameSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 8]} style={{marginTop: "-1rem"}}>
                <Col span={6}>
                  <Form.Item
                    name="sales"
                    label="Sales"
                  >
                    <QuoteSalesSelector  style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="engineers"
                    label="Engineer"
                  >
                    <InternalUserNameSelector  style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

              </Row>
              <Divider/>
            </Space>
          }]}
        />
    </Form>
  </>
}

const ApproverRoleSelector = (props:  {
  value?: string
  onChange?: (role: string) => void
  style?: any
}) => {

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ Object.values( ApproverRole ).map(role => ({
        value: role, 
        label: Utils.snakeCaseToFirstLetterCapitalized(role)
      }))}
    />
  );
};


const ApprovalRequestSelector = (props:  {
  onSelectQuoteStatus?: (status: string) => void
  value?: string
  onChange?: (statuses: string) => void
  style?: any
}) => {

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ Object.values( ApprovalRequest ).map(approvalType => ({
        value: approvalType, 
        label: Utils.formatApprovalType(approvalType),
      }))}
    />
  );
};

interface UserSelectorProps extends Omit<UserMultiSelectorProps, "value" | "onChange"> {
  value?:string[]
  onChange?: (users: string[]) => void;
}
const QuoteSalesSelector = (props: UserSelectorProps) => {

  const configurator = useContext(ConfiguratorContext);

  const intl = useIntl();
  const {userLstAsync, loadUserList} = useUsersContext();
  const userLst = userLstAsync?.val;

  const [bmSalespersonLst, bmSalespersonLstAsync] = useAsyncState<User[]>();

  useEffect(()=> {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() )  {
      loadUserList?.();
    }
  }, [ userLstAsync?.state ] );

  useEffect(() => {
    loadBmSales();
  }, [userLst])

  const user = userLst?.find( u => u.id === configurator.userInfo?.id );
  const loadBmSales = async () => {
    if ( !user?.dealerId ) return;

    bmSalespersonLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchDealerSales()
      bmSalespersonLstAsync.setDone(resp.data)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load salesperson. " + errorMsg });
      bmSalespersonLstAsync.setFail(e.message);
    };
  }

  const salesLst = user?.dealerId ? bmSalespersonLst : userLst;

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( salesLst?.filter( s => valueSet.has(s.name) ) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={salesLst} 
    onChange={(lst) => props.onChange?.( lst?.map( s => s.name ) ) }
  />
};


const InternalUserNameSelector = (props: UserSelectorProps) => {

  const {userLstAsync, loadUserList} = useUsersContext();

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = userLstAsync?.val?.filter( u => !u.dealerId );

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}


const UserNameSelector = (props: UserSelectorProps) => {

  const {userLstAsync, loadUserList} = useUsersContext();

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = userLstAsync?.val;

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}

const ModelSelector = (props:  {
  value?: string 
  onChange?: (statuses: string) => void
  style?: any
}) => {

  const [modelLst, modelLstAsync] = useAsyncState<BaseModel[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    loadModels();
  }, []);


  const loadModels = async () : Promise<BaseModel[] | undefined> => {
    modelLstAsync.setLoading();

    try {

      //todo - replace with AbortController
      //https://axios-http.com/docs/cancellation
      const resp = await configurator.api.listModels( {
        size: PAGINATION_MAX_PAGE_SIZE,
        sort: { field: 'name', direction: 'asc' },
      });
      modelLstAsync.setDone(resp.data.content);
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load models. " + errorMsg });
      modelLstAsync.setFail(e.message);
    };
    
    return;
  };


  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ modelLst?.map(model => ({
        value: model.id,
        label: model.name
      }))}
    />
  );
};

export const ApprovalDescription = (props:{
  approval:Approval
  onChange?: (() => void) | undefined
}) => {

  const { approval } = props;
  const { quoteInfo: quote } = approval;

  const configurator = useContext(ConfiguratorContext);

  const [showWorkingNoteModal, setShowWorkingNoteModal] = useState<boolean>(false);
  const [workingNote, setWorkingNote] = useState<string>();

  useEffect( () => {
    setWorkingNote( approval.workingNote );
  }, [approval]);

  const descriptionItems:DescriptionsItemType[] = [];

  descriptionItems.push( { 
    key: "quoteId", 
    label:"Quote Id", 
    children: <Link 
      target="_blank"
      to={"/configurator/" + encodeURI(approval.quoteInfo.quoteId)} >
      <span style={{whiteSpace: "nowrap"}}>{approval.quoteInfo.quoteId}</span>
    </Link>
  });
  approval.pendingSplit?.partners.map( p => ({ 
    key: "partner-" + p.id, 
    label:"Partner", 
    children: <Link
        target="_blank"
        to={"/configurator/" + encodeURI(p.quoteId)}
      >
        {p.quoteId}
      </Link>
  }))
    .forEach( n => descriptionItems.push(n) );

  if ( quote.partNumberString?.length ) {
    descriptionItems.push( { key: "partNo", label:"Part No.", children: <span style={{whiteSpace: "nowrap"}}>{quote.partNumberString}</span> });
  }

  if ( quote.serialNumberStr?.length ) {
    descriptionItems.push( { key: "serialNo", label:"Serial No.", children: <span style={{whiteSpace: "nowrap"}}>{quote.serialNumberStr}</span> });
  }


  const onEditWorkingNote = () => {
    setShowWorkingNoteModal(true);
  };

  const saveWorkingNote = async (approvalId:number, workingNote:string | undefined) => {
    if ( !workingNote ) return;

    const resp = await configurator.api.saveWorkingNote( approvalId, { workingNote });
    if (resp) {
      props.onChange?.();
      setShowWorkingNoteModal(false);
    }
  }

  return <>
    <Row key={"quote-" + approval.quoteInfo.id} gutter={24}>
      <Col span="20">
        <div>
          <Link 
            to={"/approvals/" + approval.id} >
            {approval.quoteInfo.name}
          </Link>

          {approval.pendingSplit && <Tooltip title="Split"><PartitionOutlined style={{color: "#1677ff", marginLeft: "10px"}}/></Tooltip>}
        </div>
        <Descriptions 
          column={1}
          size="small"
          items={descriptionItems}
          style={{minWidth: "14rem", marginTop: "1rem"}}
        />
      </Col>
      <Col span="4" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        {
          <div key={`${approval.quoteInfo.quoteId}-working-note`}>
            <style>
              {`
                .add-working-note-button:hover {
                color: white !important;
                background-color: #1677ff !important;
                }
                .add-working-note-button {
                color: #1677ff !important;
                border: 2px solid #1677ff !important;
                }

                .working-note-button:hover {
                color: white !important;
                background-color: orange !important;
                border: 2px solid orange !important;
                }
                .working-note-button {
                color: orange !important;
                border: 2px solid orange !important;
                }
            `}
            </style>
            {approval.workingNote ?
              <Tooltip title={approval.workingNote}>
                {approval.workingNote && String(approval.workingNote).trim() !== '' && <Button className="working-note-button" shape="circle" icon={<InfoOutlined /> } onClick={() => onEditWorkingNote()} size='small'></Button>}
              </Tooltip>
              :
              <Tooltip title={'Edit working note.'}>
                {<Button className="add-working-note-button" shape="circle" icon={<LeftOutlined />} size='small' onClick={() => onEditWorkingNote()}></Button>}
              </Tooltip>}
          </div>
        }
      </Col>
    </Row>
    <Modal title="Edit Working Note"
      open={showWorkingNoteModal}
      maskClosable={false}
      onOk={() => saveWorkingNote( approval.id, workingNote )}
      onCancel={() => setShowWorkingNoteModal(false)}
      okText="Save"
      bodyStyle={{ height: 480 }}
      width={590}
    >
      <Input.TextArea rows={4} placeholder={'Please enter working note here.'} value={workingNote} onChange={(e) => setWorkingNote(e.target.value)} />
    </Modal>
  </>;
}


export default Approvals;
