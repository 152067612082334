import "../util/mobile-table.css";
import styles from "./orders.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Button, Space, notification, Card, Col,  } from "antd";
import  { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import { BaseQuote, DateFilterType, ORDER_TABLE, PAGINATION_MAX_PAGE_SIZE, QuoteFilter } from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import { getCSVRow } from "../helpers/csv";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import QuoteFilterControls from "../components/QuoteFilterControls";
import { getMyQuotesPermission } from "./quotes";

type  BaseQuoteSort = SorterResult<BaseQuote> | SorterResult<BaseQuote>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: BaseQuoteSort) => void

const getProductionDateStr = (date?: Date | undefined ) => {
  return date ? dayjs(date).format("MMMM Do YYYY") : 'Not Available';
};

const DEFAULT_PAGE_SIZE = 20;
const Orders = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const [_quoteList, quoteListAsync] = useAsyncState<BaseQuote[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [myQuotesParam, setMyQuotesParam] = useQueryParam<boolean | undefined | null>("my", BooleanParam);
  const [archivedParam, setArchivedParam] = useQueryParam<boolean | undefined | null>("includingArchived", BooleanParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [quoteStatus, setQuoteStatus] = useQueryParam("status", ArrayParam);
  const [quoteSalespersonsParam, setQuoteSalespersonsParam] = useQueryParam("salespersons", ArrayParam);
  const [quoteEngineersParam, setQuoteEngineersParam] = useQueryParam("engineers", ArrayParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [modelsParam, setModelsParam] = useQueryParam("models", NumericArrayParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerIds", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationIds", NumericArrayParam);
  const [includingCancelledParam, setIncludingCancelledParam] = useQueryParam<boolean | undefined | null>("includingCancelledOrder", BooleanParam);
  const [draftOnlyParam, setDraftOnlyParam] = useQueryParam<boolean | undefined | null>("hasDraftRevision", BooleanParam);

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: myQuotesParam ?? !getMyQuotesPermission(configurator),
    includingArchived: archivedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    filterAssemblies: filterAssemblies as number[] || undefined,
    quoteStatus: [quoteStatus as (string|null) || []].flat() || undefined,
    engineers: [quoteEngineersParam as (string|null) || []].flat(),
    salespersons: [quoteSalespersonsParam as (string|null) || []].flat(),
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
    includingCancelledOrder: includingCancelledParam || false,
    models: [modelsParam as (number | null) || []].flat(),
    hasDraftRevision: draftOnlyParam || false,
    // ...props.defaultFilter,
  });

  const defaultSort = {
    columnKey: 'latestRevision.summary.productionDate',
    order: 'descend' as SortOrder
  };
  const [sort, setSort] = useState<BaseQuoteSort>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  //change in filters should trigger refetch
  useEffect(() => {
      loadQuotes(quoteListAsync, pagination, filter , sort );
  }, [ filter, pagination.pageSize, pagination.current, sort ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const loadQuotes = useCallback(debounce( async ( quoteListAsync:AsyncState<BaseQuote[]>, pagination: TablePaginationConfig, filter: QuoteFilter, sorter:BaseQuoteSort ) => {

    var sort = [ sorter ].flat()[0];
    quoteListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuotes({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        ordersOnly: true,
        includingArchived: false,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'ascend' ? 'asc' : 'desc',
        }
      })
      quoteListAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Quotes failed to load. " + errorMsg});
      quoteListAsync.setFail( e.message );
    }

  }, 400 ), [] );


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: BaseQuoteSort) => {
    setPagination(pagination);

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = ( _values: Record<string, any>, filter:QuoteFilter ) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setArchivedParam(filter.includingArchived);
    setMyQuotesParam(filter.myQuotes);
    setDealerFilterParam( filter.dealerLst );
    setAssembliesFilter(filter.filterAssemblies);
    setQuoteStatus(filter.quoteStatus);
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setQuoteSalespersonsParam(filter.salespersons);
    setQuoteEngineersParam(filter.engineers);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    setIncludingCancelledParam(filter.includingCancelledOrder);
    setDraftOnlyParam(filter.hasDraftRevision);
    setModelsParam(filter.models);
    setFilter(filter);
  };

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!archivedParam && setArchivedParam(undefined);
    !!myQuotesParam && setMyQuotesParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!filterAssemblies?.length && setAssembliesFilter(undefined);
    !!quoteStatus?.length && setQuoteStatus(undefined);
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!quoteSalespersonsParam?.length && setQuoteSalespersonsParam(undefined);
    !!quoteEngineersParam?.length && setQuoteEngineersParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
    !!draftOnlyParam && setDraftOnlyParam(undefined);
    !!modelsParam?.length && setModelsParam(undefined);
    setIncludingCancelledParam(undefined);
  }

  const exportOrders = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.listQuotes({
        ...filter,
        ordersOnly: true,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'desc',
          field: 'latestRevision.summary.productionDate',
        }
      })).data;

      var csv = [[
        'Name',
        'QuoteID',
        'Status',
        'Salesperson',
        'Model',
        'Quantity',
        'Truck Description',
        'Engineer',
        'EstimatedProductionDate',
      ].join(',')];

      resp.content.forEach((quote: BaseQuote) => {
        csv.push(getCSVRow([
          quote.name,
          quote.quoteId,
          quote.status,
          quote.owner?.name || '',
          quote.model.name,
          String(quote.quantity),
          quote.truckDescription || '',
          quote.salesTeam?.engineers?.map(u => u.name).join( " + " ) || '',
          getProductionDateStr(quote.productionDate),
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'orders-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>Orders</Title>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <QuoteFilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
          tableName={ORDER_TABLE}
        />

        {isMobile 
          ? <MobileTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
            sort={sort}
            isExporting={isExporting}
            exportOrders={exportOrders}
          />
          : <DesktopTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
            sort={sort}
            isExporting={isExporting}
            exportOrders={exportOrders}
          />
        }
      </Space>
    </div>
  );
};

const DesktopTable = (props: {
  quoteListAsync: AsyncState<BaseQuote[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  sort:BaseQuoteSort
  isExporting: boolean
  exportOrders:()=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, sort, isExporting, exportOrders  } = props;
  const quoteList = quoteListAsync.val;

  const configurator = useContext(ConfiguratorContext);
  const hasComparePermissions = configurator.isSalesDesk() || configurator.isAdmin() || configurator.isEngineering();

  const firstSort = Array.isArray(sort) ? sort[0] : sort;

  let columns:ColumnType<BaseQuote>[] = [
    {
      title: "Name",
      key: "name",
      render: (q:BaseQuote) => <Link to={"/configurator/" + encodeURI(q.quoteId)}>{q.name}</Link>,
    },
    {
      title: "Quote ID",
      dataIndex: "quoteId",
      key: "quoteId",
    },
    {
      title: "Status",
      key: "status",
      render: (q:BaseQuote) => Utils.formatQuoteStatus(q),
    },
    {
      title: "Model",
      key: "modelName",
      dataIndex: "modelName",
      render: (_, q) => <span>{q.model.name}</span>,
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "Truck Description",
      key: "truckDescription",
      dataIndex: "truckDescription",
    },
    {
      title: "Estimated Production Date",
      key: "latestRevision.summary.productionDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (q:BaseQuote) => (
        <span>{getProductionDateStr(q.productionDate)}</span>
      ),
    },
  ];

  if ( configurator.isInternalSales() ) {
    const SALES_COL_NDX = 3;
    columns = columns.slice(0,SALES_COL_NDX)
    .concat( {
      title: "Engineer",
      key: "salesTeam.engineerMembers.user.name",
      render: (_owner, q) => q.salesTeam?.engineers?.map( u => u.name).join(", "),
      sorter: true,
    })
    .concat( columns.slice(SALES_COL_NDX) );
  }

  //quick fix to handle no elements
  const topButtonSection: any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem", marginTop: "1rem" };

  return <>
        <div style={{ position: "relative" }}>
          <Col style={topButtonSection}>
            <Space direction="horizontal">
              <Button type="primary" loading={isExporting} onClick={exportOrders}>Export</Button>
              {hasComparePermissions && <DiffRevisionModalButton btnLabel="Compare" title="Quote Comparison" className="" type="primary"  /> }
              <Link to="/configurator"><Button type='primary'>New</Button></Link>
            </Space>
          </Col>

          <Table
            loading={quoteListAsync.isLoading()}
            onChange={tableOnChange}
            bordered
            pagination={pagination}
            dataSource={quoteList}
            columns={columns}
            rowKey="quoteId"
          />
        </div>
    </>
}

const MobileTable = (props:{
  quoteListAsync:AsyncState<BaseQuote[]>
  tableOnChange: TableOnChangeFn
  pagination:TablePaginationConfig
  sort:BaseQuoteSort
  isExporting: boolean
  exportOrders:()=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, sort, isExporting, exportOrders } = props;
  const quoteList = quoteListAsync.val;

  const firstSort = Array.isArray(sort) ? sort[0] : sort;

  const columns:ColumnType<BaseQuote>[] = [
    {
      key: "latestRevision.summary.productionDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (_val, quote, _ndx) => {
        return <>
            <Card 
              hoverable={true}
              title={<>
                <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.name}</div>
                <div className={styles["quote-id"]}>({quote.quoteId})</div>
              </>
              } 
            >
              <div className="section" style={{maxWidth: "17rem"}}>
                <div className={styles["quote-status"]}>{Utils.formatQuoteStatus(quote)}</div>
              </div>

              <div style={{display: "flex", flexWrap: "wrap", gap: "3rem"}}>
                <div className="section">
                  <div>Salesperson:</div>
                  <div><span>{quote?.owner?.name}</span></div>
                  <div><span>{dayjs(quote.updatedAt).format("MMMM Do YYYY")}</span></div>
                </div>

                {quote?.serialNumberStr &&
                  <div className="section">
                    <div>Serial No:</div>
                    <div><span>{quote?.serialNumberStr}</span></div>
                  </div>
                }

              </div>
            </Card>
        </>;
      },
    },

  ];

  //quick fix to handle no elements
  const newButtonStyle:any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem" };

  return <>
    <div style={{ position: "relative" }}>
      <Button type="primary" style={newButtonStyle} loading={isExporting} onClick={exportOrders}>Export</Button>

      <Table
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="id"
        className="mobile-table"
      />
    </div>
  </>
}



export default Orders;
