import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { AXIOS_CANCEL_MSG, Category, DEFAULT_THROTTLE } from "../api/models";
import { notification } from "antd";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";
import { throttle } from "lodash";
import axios, {CancelTokenSource} from "axios";

export interface CategoryContextType {
  categoriesAsync?: AsyncState<Category[]>
  loadCategories?:(modelId?:number)=>Promise<Category[] | undefined>
}
const CategoryContext = React.createContext<CategoryContextType>({});

export const useCategoryContext = () : CategoryContextType => {
    return useContext(CategoryContext);
}

// Data provider component
const CategoryContextProvider = (props:{children: ReactElement | ReactElement[] }) => {

  const [_categories, categoriesAsync] = useAsyncState<Category[]>([]);

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const context = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    reloadCategories();
  }, []);

  const reloadCategories = async () : Promise<Category[] | undefined> => loadCategories(categoriesAsync);
  
  const loadCategories = useCallback(throttle( async (categoriesAsync:AsyncState<Category[]>) : Promise<Category[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    categoriesAsync.setLoading();
    try {
      const resp = await context.api.getCategories();
      //most of the time we want them sorted by name
      const categoryLst = resp.data.sort((a, b) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ) );

      cancelTokenSourceRef.current = undefined;

      categoriesAsync.setDone( categoryLst );
      return resp.data;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get categories. " + errorMsg });
      categoriesAsync.setFail(e.message);
    }
    return;
  }, DEFAULT_THROTTLE), []);

  const categoryContext = useMemo<CategoryContextType>( () => ({
    categoriesAsync, 
    loadCategories:reloadCategories
  }) , [ categoriesAsync ]);

  return <CategoryContext.Provider value={categoryContext}>{props.children}</CategoryContext.Provider>;
};
  
export default CategoryContextProvider;


