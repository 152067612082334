import "./PricingView.css";
import {DealerAdjustment, PricingBreakdown, NonDiscountOption, Assembly} from "../../api/models";
import Utils from "../../util/util";
import {Button, ButtonProps, Dropdown } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import PricingOptionButtonModal, {PricingOptionCustomOption} from "./PricingOptionButtonModal";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { ConfiguratorContext } from "../../context";
import { ItemType } from "antd/es/menu/interface";


export type DealerAdjustmentsButtonModalProps =  Omit<ButtonProps, "value" | "onChange"> & {
  value?:DealerAdjustment
  onChange?: (v: DealerAdjustment | undefined) => void
};
const DealerAdjustmentsButtonModal = (props: DealerAdjustmentsButtonModalProps) => {

  const predefinedOptions = [
      "FET", "State Taxes", "Reg Fees", "Interest", "Freight", "Warranty", "Training", "Dealer Commission", "Penalty", PricingOptionCustomOption
  ]

  return <PricingOptionButtonModal {...props} 
    title={"Dealer Adjustments"}
    optionsLst={predefinedOptions}
  />
}


export type NonDiscountOptionsButtonModalProps =  Omit<ButtonProps, "value" | "onChange"> & {
  value?:NonDiscountOption
  onChange?: (v: NonDiscountOption | undefined) => void
};
const NonDiscountOptionsButtonModal = (props:NonDiscountOptionsButtonModalProps) => {

  const predefinedOptions = [
    "Pilot Inspection Fee", PricingOptionCustomOption
  ];

  return <PricingOptionButtonModal {...props} 
    title={"Non-Discount Options"}
    optionsLst={predefinedOptions}
  />
}


const NOT_FOUND = -1;
interface Properties {
    pricingDetails: PricingBreakdown | undefined
    onChangePricingDetails?: ( p:PricingBreakdown ) => void
    disabled?: boolean
    incentivePrograms?: string[] | undefined
};
const PricingView = (props:Properties) => {
    const { pricingDetails, onChangePricingDetails, disabled, incentivePrograms } = props;

    const configurator = useContext(ConfiguratorContext);
    const { quoteAsync, adminView, isLocked } = useQuoteContext();

  const {
    isReadOnly
  } = Utils.getQuoteState(configurator, quoteAsync, isLocked);


    const showDetails = adminView;

    if ( !pricingDetails ) return <>Not Available</>;

    const isPercentDiscountNonZero = pricingDetails.percentDiscount != 0.0;
    const dealerPercentDiscount =  isPercentDiscountNonZero 
        ? -1 * pricingDetails.dealerNet * pricingDetails.percentDiscount / 100
        : 0;
    const isDealerDiscountedNetDiff = pricingDetails.dealerDiscountedNet != pricingDetails.dealerNet;
    const dealerDiscount = -1  * ( pricingDetails.dealerMsrp - pricingDetails.dealerNet );

    const handleChangeDealerAdjustments = ( existingDa?:DealerAdjustment | undefined) => {
      return (updatedDa: DealerAdjustment | undefined) => {
        if ( existingDa ) {
          const ndx = pricingDetails.dealerAdjustments.findIndex(da => da.key === existingDa?.key );
          if ( ndx !== NOT_FOUND ) {
            pricingDetails.dealerAdjustments.splice( ndx, 1 );
          }
        }
        if ( updatedDa ) {
          pricingDetails.dealerAdjustments.push( updatedDa );
        }
        pricingDetails.dealerAdjustments.sort( (a,b) => (a.label || "").localeCompare(b.label || "") );

        onChangePricingDetails?.( pricingDetails );
      }
    }

    const handleChangeNonDiscountOptions = ( existingNdo?:NonDiscountOption | undefined) => {
      return (updatedNdo: NonDiscountOption | undefined) => {
        if ( existingNdo ) {
          const ndx = pricingDetails.nonDiscountOptions.findIndex(da => da.key === existingNdo?.key );
          if ( ndx !== NOT_FOUND ) {
            pricingDetails.nonDiscountOptions.splice( ndx, 1 );
          }
        }
        if ( updatedNdo ) {
          pricingDetails.nonDiscountOptions.push( updatedNdo );
        }
        pricingDetails.nonDiscountOptions.sort( (a,b) => ( a.label || "").localeCompare(b.label || "") );

        onChangePricingDetails?.( pricingDetails );
      }
    }

    const handleAddBtn = () => {
    }

    const getHeaderFontSize = (txt:string|undefined) => {
      const HEADER_LINE_LENGTH = 30;
      const txtLength = txt?.length || 0;

      if (txtLength > ( HEADER_LINE_LENGTH * 2 ) ) return "7pt";
      if (txtLength > HEADER_LINE_LENGTH ) return "9pt"

      return "inherit";
    }

    const hasIncentiveProgram = !!incentivePrograms?.length;

    const dropdownItems:ItemType[] = []
    dropdownItems.push( {
      key: "da",
      label: <DealerAdjustmentsButtonModal style={{border: "none"}} 
        onChange={handleChangeDealerAdjustments()}
      /> 
    });

    if (!isReadOnly ) {
      dropdownItems.push( {
        key: "ndo",
        label: <NonDiscountOptionsButtonModal style={{border: "none"}} 
          onChange={handleChangeNonDiscountOptions()}
        />
      });
    }

    return (
        <div className={"pricingView"}>
          <h3>Base Price 
            {!disabled &&
              <div style={{float:"right"}}>
                <Dropdown trigger={["click"]} 
                  menu={{items:dropdownItems}}
                >
                  <Button data-testid="pricing-view-add-btn" shape="circle" type="primary" icon={<PlusOutlined />} size="small" onClick={handleAddBtn} />
                </Dropdown>
              </div>}
          </h3>

            <table className="basePrices">
                <tbody>
                    {showDetails && (
                        <>
                            <tr>
                                <th>Material Cost:</th>
                                <td>{Utils.formatMoney( pricingDetails.materialPrice ) }</td>
                            </tr>
                            <tr>
                                <th>Labor Cost:</th>
                                <td>{Utils.formatMoney( pricingDetails.laborPrice ) }</td>
                            </tr>
                            <tr>
                                <th>Inbound Freight:</th>
                                <td>{Utils.formatMoney( pricingDetails.inboundFreight ) }</td>
                            </tr>
                            <tr>
                                <th>Tariffs Surcharges:</th>
                                <td>{Utils.formatMoney( pricingDetails.tariffsSurcharges ) }</td>
                            </tr>
                            <tr>
                                <th>PDI:</th>
                                <td>{Utils.formatMoney( pricingDetails.pdi ) }</td>
                            </tr>
                            <tr>
                                <th>Warranty:</th>
                                <td>{Utils.formatMoney( pricingDetails.warranty ) }</td>
                            </tr>
                            <tr>
                                <th>Gross Margin:</th>
                                <td>{Utils.formatMoney( pricingDetails.grossMargin ) }</td>
                            </tr>
                            {(pricingDetails.priceMatch != 0)  && <tr>
                              <th>Price Match:</th>
                                <td>{Utils.formatMoney( pricingDetails.priceMatch) }</td>
                            </tr>}
                        </>
                    ) }
                    {pricingDetails && pricingDetails.customOptionsMsrp >= 0 && <tr>
                        <th>Custom Option(s) MSRP:</th>
                        <td>{Utils.formatMoney( pricingDetails.customOptionsMsrp ) }</td>
                    </tr>}
                    <tr>
                        <th>Dealer MSRP:</th>
                        <td>{Utils.formatMoney( pricingDetails.dealerMsrp ) }</td>
                    </tr>
                    <tr>
                        <th>Dealer Discount:</th>
                        <td>{Utils.formatMoney( dealerDiscount ) }</td>
                    </tr>
                    <tr>
                        <th>Dealer Net:</th>
                        <td>{Utils.formatMoney( pricingDetails.dealerNet ) }</td>
                    </tr>

                    {isPercentDiscountNonZero && (
                        <tr>
                            <th>{pricingDetails.percentDiscount?.toFixed( 2 )} % discount:</th>
                            <td>{Utils.formatMoney( dealerPercentDiscount ) }</td>
                        </tr>
                    ) }
                    {pricingDetails.nonDiscountedNetItems.map(
                        (item: Assembly ) => (
                            <tr key={item.id}>
                              <th style={{fontSize: getHeaderFontSize(item.label)}}>{item.label}:</th>
                                <td>{Utils.formatMoney( item.standardMaterialCost||0.0 ) }</td>
                            </tr>
                        )
                        )}

                    {showDetails && (
                            <tr>
                                <th>Commission Cost:</th>
                                <td>{ Utils.formatMoney( pricingDetails.commissionCost ) }</td>
                            </tr>
                    )}

                </tbody>
            </table>

          {!!pricingDetails.nonDiscountOptions.length && <table className="basePrices" key={`ndo-table`}>
            <tbody>
            <tr>
              <th >Non-Discount Options</th>
              <th ></th>
            </tr>
              {pricingDetails.nonDiscountOptions.map( (ndo, ndx) => <React.Fragment key={`ndo-node-${ndx}`}>
              <tr>
                <th>
                  <NonDiscountOptionsButtonModal value={ndo} disabled={isReadOnly || disabled} style={{height: "auto"}} onChange={handleChangeNonDiscountOptions(ndo)} />
                </th>
                <td className="price">{Utils.formatMoney(ndo.value)}</td>
              </tr>
              {!!ndo.notes &&
              <tr>
                <td colSpan={2} style={{fontStyle:"italic"}} title={ndo.notes}>{ndo.notes}</td>
                </tr> }
              </React.Fragment>)}
            </tbody>
          </table>}                      


            <table className="basePrices">
                <tbody>

                    {isDealerDiscountedNetDiff && (
                        <tr>
                            <th >Net Price:</th>
                            <td>{Utils.formatMoney( pricingDetails.dealerDiscountedNet ) }</td>
                        </tr>
                    )}
                    {pricingDetails.nonDiscountedItems.map(
                        (item: Assembly) => (
                            <tr key={item.id}>
                                <th style={{fontSize:getHeaderFontSize(item.label) }}>{item.label}:</th>
                                <td>{Utils.formatMoney( item.standardMaterialCost||0.0 ) }</td>
                            </tr>
                        )
                        )}
                    {pricingDetails.incentivePrograms
                      .filter(i => ( i.cost || 0 ) > 0 )
                      .map( i => (
                            <tr key={i.name}>
                                <th style={{fontSize:getHeaderFontSize(i.name) }}>{i.name}:</th>
                                <td>{Utils.formatMoney( i.cost||0.0 ) }</td>
                            </tr>
                        )
                        )}
                    {pricingDetails.shippingDestination != null && (
                        <tr>
                            <th>Destination Charge:</th>
                            <td>{ Utils.formatMoney( pricingDetails.shippingDestination ) }</td>
                        </tr>
                    )}
                  <tr className="summaryRow" >
                        <th>Dealer Price:</th>
                        <td>{ Utils.formatMoney( pricingDetails.dealerPrice ) }</td>
                    </tr>
                </tbody>
            </table>

          {!!pricingDetails.dealerAdjustments.length && <table className="basePrices" key={`da-table`}>
            <tbody>
              <tr>
                <th >Dealer Adjustments</th>
                <th ></th>
              </tr>
              {pricingDetails.dealerAdjustments.map( (da, ndx) => <React.Fragment key={`da-node-${ndx}`}>
                <tr>
                  <th>
                    <DealerAdjustmentsButtonModal disabled={disabled} value={da} style={{height: "auto"}} onChange={handleChangeDealerAdjustments(da)} />
                  </th>
                  <td className="price">{Utils.formatMoney(da.value)}</td>
                </tr>
                {!!da.notes && 
                <tr>
                  <td colSpan={2} style={{fontStyle:"italic"}} title={da.notes}>{da.notes}</td>
                </tr> }
              </React.Fragment>)}
            </tbody>
          </table>}                      

            <table className="basePrices">
                <tbody>
                    <tr className="summaryRow">
                        {hasIncentiveProgram ? (
                            <>
                                <th>Customer Price:</th>
                                <td>{ Utils.formatMoney( pricingDetails.totalPrice ) }</td>
                            </>
                        ) : (
                            <>
                                <th>Total Price:</th>
                                <td>{ Utils.formatMoney( pricingDetails.totalPrice ) }</td>
                            </>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>

    );

}


export default PricingView


