import { Form, notification, Row, Select, Space, Spin, Table, TableColumnType } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../../context";
import { InputDateTimeFormat, MotorFamily, PeriodProduction, ProductionLine, ScheduleStatictics } from "../../api/models";
import Utils from "../../util/util";
import { useWatch } from "antd/es/form/Form";
import { useIntl } from "react-intl";
import { useAsyncState } from "../../hook/useAsyncState";

const ALL = "ALL";

const MasterScheduleKpi = () => {

  const configurator = useContext(ConfiguratorContext);
  const [form] = Form.useForm();
  const endYear = useWatch("endYear", form); 
  const lines: string[] = useWatch('productionLines', form);
  const [statistics, statisticsAsyncState] = useAsyncState<ScheduleStatictics[]>();
  const intl = useIntl();

  const endDate = dayjs(`${endYear}-12-31`); 

  useEffect(() => {
    const inptuLines = lines?.includes(ALL) ? Object.values(ProductionLine) : lines;
    getStatistics(inptuLines);
  }, [endYear, lines]);

  const getStatistics = async (inputLines) => {
    if (!endYear || !lines?.length) return;
    statisticsAsyncState.setLoading();
    try {
      const startDate = endDate.subtract(1, 'year');
      const resp = await configurator.api.getMasterScheduleKpi(inputLines, startDate.format(InputDateTimeFormat), endDate.format(InputDateTimeFormat));
      statisticsAsyncState.setDone(resp.data)
    }
    catch(e: any) {
      const msg = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id: msg });
      console.log(errorMsg);
      notification.error({message: "Failed to load statistics."})
    }
  }

  const aggregateStatistics = (statistics: ScheduleStatictics[]): ScheduleStatictics => {

    if (statistics.length === 0) {
      return { bevProduction: [], iceProduction: [] };
    }
  
    const productionLength = statistics[0].bevProduction?.length || 0;
  
    const aggregateProduction = (key: "bevProduction" | "iceProduction") => {
      const aggregated: PeriodProduction[] = [];
  
      for (let i = 0; i < productionLength; i++) {
        const combinedElement: PeriodProduction = {
          startDate: statistics[0][key]?.[i].startDate,
          endDate: statistics[0][key]?.[i].endDate,
          availableDays: statistics[0][key]?.[i].availableDays,
          productionLine: ALL,
          scheduledTrucks: 0,
          trucksInProduction: 0,
          iceTotalSlots: 0,
          bevTotalSlots: 0,
          bevTaktRate: 0,
          iceTaktRate: 0,
        };
  
        // Sum up the numeric fields
        statistics.forEach((stat) => {
          const currentElement = stat[key]?.[i];
          if (currentElement) {
            combinedElement.scheduledTrucks =
              (combinedElement.scheduledTrucks || 0) +
              (currentElement.scheduledTrucks || 0);
            combinedElement.trucksInProduction =
              (combinedElement.trucksInProduction || 0) +
              (currentElement.trucksInProduction || 0);
            combinedElement.iceTotalSlots =
              (combinedElement.iceTotalSlots || 0) +
              (currentElement.iceTotalSlots || 0);
            combinedElement.bevTotalSlots =
              (combinedElement.bevTotalSlots || 0) +
              (currentElement.bevTotalSlots || 0);
            combinedElement.bevTaktRate =
              (combinedElement.bevTaktRate || 0) +
              (currentElement.bevTaktRate || 0);
            combinedElement.iceTaktRate =
              (combinedElement.iceTaktRate || 0) +
              (currentElement.iceTaktRate || 0);
          }
        });
  
        aggregated.push(combinedElement);
      }
  
      return aggregated;
    };
  
    return {
      prouctionLine: ALL,
      bevProduction: aggregateProduction("bevProduction"),
      iceProduction: aggregateProduction("iceProduction"),
    };
  };

  const handleLineChange = (selectedLines: string[]) => {

    if (!lines?.includes(ALL) && selectedLines.includes(ALL)) {
      // If "ALL" is selected, keep only "ALL"
      form.setFieldsValue({ productionLines: [ALL] });
    } else if (lines?.includes(ALL)) {
      // If "ALL" was already selected and other items are selected, remove "ALL"
      form.setFieldsValue({ productionLines: selectedLines.filter(line => line !== ALL) });
    } else {
      // Otherwise, update the selection as usual
      form.setFieldsValue({ productionLines: selectedLines });
    }
  };
  
  return (<>
    <style>
    {`

    `}
    </style>
    <div className="site-layout-background">
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: "space-between", gap: '5px' }}>
          <Title level={2}>Master Schedule KPI</Title>
        </div>

        <Form
          form={form}
          layout="horizontal"
          initialValues={{
            productionLines: "ALL",
            endYear: dayjs().year().toString(),
          }}
        >
          <Row justify={"space-between"}>
            <Form.Item
              name="productionLines"
            >
                <Select
                  placeholder="Select production line"
                  style={{ width: 300 }}
                  mode="multiple"
                  options={Object.values(ProductionLine).map(v => String(v))?.concat(ALL)?.map((line) => ( {
                    value: line,
                    label: Utils.snakeCaseToFirstLetterCapitalized(line === ALL ? ALL : line.split("_").slice(1).join("_"))
                  }))}
                onChange={handleLineChange}
                />
            </Form.Item>
            <Form.Item name="endYear">
                <Select
                  placeholder="Select year"
                  style={{ width: "300px" }}
                  options={Array.from({ length: 20 }, (_, i) => {
                    const year = dayjs().year() + 10 - i;
                    return { value: year, label: year.toString() };
                  })}
                />
              </Form.Item>
          </Row>
        </Form>

        {statisticsAsyncState.isLoading() ? (
          <Row justify={"center"}>
            <Spin />
          </Row>
        ) : !!endYear && !!lines?.length ? (
          (lines?.includes(ALL)) ? (
            // Aggregate all statistics into combined data
            <ProductionLineStatistics
              statistics={aggregateStatistics(statistics || [])}
              line={ALL}
              endDate={endDate.toDate()}
            />
          ) : (
            // Render separate tables for each selected line
            statistics?.map((stat, idx) => (
              <div key={`${idx}-stat-div`}>
                {idx !== 0 && <br />}
                <ProductionLineStatistics
                  key={`${idx}-stat`}
                  statistics={stat}
                  line={lines[idx]}
                  endDate={endDate.toDate()}
                />
              </div>
            ))
          )
        ) : null}

      </Space>
    </div>
    </>);
}

export default MasterScheduleKpi;


interface StatisticsProps {
  statistics: ScheduleStatictics;
  line: string;
  endDate: Date;
}

const HEADER_DATE_FORMAT = "MMM-YYYY";

const ProductionLineStatistics = (props: StatisticsProps) => {

  const {statistics, line, endDate} = props;

  interface TableRow {
    key: string;
    metric: string;
    [month: string]: any;
  }
  
  const generateColumns = (productionData: PeriodProduction[] | undefined): TableColumnType<TableRow>[] => {
    if (!productionData) return [];
  
    const columns: TableColumnType<TableRow>[] = [];
    const quarterColumns: TableColumnType<TableRow>[] = [];
    let currentYearTotalDays = 0;
    let currentQuarterTotalDays = 0;
    let currentQuarterIndex = 1;
  
    productionData.forEach((p, index) => {
      const monthKey = dayjs(p.endDate).format(HEADER_DATE_FORMAT);
      const availableDays = p.availableDays;
  
      // monthly column
      columns.push({
        title: (
          <div>
            <div>{monthKey}</div>
            <div style={{ fontSize: "13px" }}>{availableDays}</div>
          </div>
        ),
        dataIndex: monthKey,
        key: monthKey,
        align: "center",
      });
  
      currentQuarterTotalDays += availableDays || 0;
      currentYearTotalDays += availableDays || 0;
  
      if ((index + 1) % 3 === 0 || index === productionData.length - 1) {
        const quarterKey = `Q${currentQuarterIndex}`;
        quarterColumns.push({
          title: (
            <div>
              <div>{quarterKey}</div>
              <div style={{ fontSize: "13px" }}>{currentQuarterTotalDays}</div>
            </div>
          ),
          dataIndex: quarterKey,
          key: quarterKey,
          align: "center",
          className: `quarter-column`,
        });
  
        currentQuarterTotalDays = 0;
        currentQuarterIndex++;
      }
    });
  

    const finalColumns: TableColumnType<TableRow>[] = [];
    let quarterColumnIndex = 0;
  
    for (let i = 0; i < columns.length; i++) {
      finalColumns.push(columns[i]);
  
      if ((i + 1) % 3 === 0 && quarterColumnIndex < quarterColumns.length) {
        finalColumns.push(quarterColumns[quarterColumnIndex++]);
      }
    }

    // Total column
    finalColumns.push({
      title: (
        <div>
          <div>Total</div>
          <div style={{ fontSize: "13px" }}>{currentYearTotalDays}</div>
        </div>
      ),
      dataIndex: "total",
      key: "total",
      align: "center",
      className: `total-column`,
    });
  
    // Add the "Metric" column at the start
    finalColumns.unshift({
      title: (
        <div>
          <br />
          <div style={{ fontSize: "11px" }}>Prod.Days</div>
        </div>
      ),
      dataIndex: "metric",
      key: "metric",
      fixed: "left",
      width: 200,
    });
  
    return finalColumns;
  };
  

  const generateDataSource = (
    productionData: PeriodProduction[] | undefined,
    metrics: { key: string; metric: string; accessor: (p: PeriodProduction) => any }[]
  ): TableRow[] => {
    if (!productionData) return [];

    const averageValueColumn = ['Daily Build'];
  
    return metrics.map((metric) => {
      const row = {
        key: metric.key,
        metric: metric.metric,
      } as TableRow;
  
      let currentQuarterSum = 0;
      let currentYearSum = 0
      let currentQuarterIndex = 1;
  
      productionData.forEach((p, index) => {
        const monthKey = dayjs(p.endDate).format(HEADER_DATE_FORMAT);
        const value = metric.accessor(p);

        const roundedValue = Number.isInteger(value) ? value : Math.round(value * 10) / 10;

  
        row[monthKey] = roundedValue;
  
        currentQuarterSum += roundedValue;
        currentYearSum += roundedValue;
  
        if ((index + 1) % 3 === 0 || index === productionData.length - 1) {
          const quarterKey = `Q${currentQuarterIndex}`;
          if (averageValueColumn.some(c => metric.metric.includes(c))) {
            row[quarterKey] = Math.round(currentQuarterSum / 3 * 10) / 10;
          }
          else {
            row[quarterKey] = currentQuarterSum;
          }
          currentQuarterSum = 0;
          currentQuarterIndex++;
        }
      });

      // Add Total column data
      const yearKey = "total";
      if (averageValueColumn.some(c => metric.metric.includes(c))) {
        row[yearKey] = Math.round(currentYearSum / 12 * 10) / 10;
      }
      else {
        row[yearKey] = currentYearSum;
      }
  
      return row;
    });
  };

  const getMetrics = (modelFamily: MotorFamily) => {
    return [
      {
        key: `${String(modelFamily).toLowerCase()}_${line}_totalSlots`,
        metric: `${String(modelFamily).toLocaleUpperCase()} Plan`,
        accessor: (p: PeriodProduction) => p[`${String(modelFamily).toLowerCase()}TotalSlots`],
      },
      {
        key: `${String(modelFamily).toLowerCase()}_${line}_taktRate`,
        metric: `${String(modelFamily).toLocaleUpperCase()} Daily Build`,
        accessor: (p: PeriodProduction) => p[`${String(modelFamily).toLowerCase()}TaktRate`],
      },
      {
        key: `${String(modelFamily).toLowerCase()}_${line}_scheduledTrucks`,
        metric: `${String(modelFamily).toLocaleUpperCase()} Truck Scheduled`,
        accessor: (p: PeriodProduction) => p.scheduledTrucks,
      },
      {
        key: `${String(modelFamily).toLowerCase()}_${line}_openSlots`,
        metric: `${String(modelFamily).toLocaleUpperCase()} Open Slots`,
        accessor: (p: PeriodProduction) => (p[`${String(modelFamily).toLowerCase()}TotalSlots`] || 0) - (p.scheduledTrucks || 0),
      },
    ];
  };
  

  const bevProduction = statistics?.bevProduction;
  const iceProduction = statistics?.iceProduction;

  const getDataSource = () => {
    const bevMetrics = getMetrics(MotorFamily.BEV);
    const iceMetrics = getMetrics(MotorFamily.ICE);

    const bevDataSource = generateDataSource(bevProduction, bevMetrics);
    const iceDataSource = generateDataSource(iceProduction, iceMetrics);
    return [bevDataSource, iceDataSource];
  }

  const getColumns = () => {
    const bevColumns = generateColumns(bevProduction);
    const iceColumns = generateColumns(iceProduction);
    return [bevColumns, iceColumns];
  }

  const [bevDataSource, iceDataSource] = getDataSource();
  const [bevColumns, iceColumns] = getColumns();


  const generateSummaryDataSource = (
    bevDataSource: TableRow[],
    iceDataSource: TableRow[]
  ): TableRow[] => {
    const metricsToSummarize = ["totalPlan", "scheduledTrucks", "totalOpenSlots"];
    const summaryRows: TableRow[] = [];
  
    metricsToSummarize.forEach((metricKey) => {
      let metricLabel: string;
  
      switch (metricKey) {
        case "scheduledTrucks":
          metricLabel = "Total Scheduled Trucks";
          break;
        case "totalPlan":
          metricLabel = "Total Plan";
          break;
        case "totalOpenSlots":
          metricLabel = "Total Open Slots";
          break;
        default:
          return;
      }
  
      const combinedRow: TableRow = {
        key: metricKey,
        metric: metricLabel,
      };
  
      if (metricKey === "totalOpenSlots") {

        const totalPlanRow = summaryRows.find((row) => row.key === "totalPlan");
        const scheduledTrucksRow = summaryRows.find((row) => row.key === "scheduledTrucks");
  
        if (totalPlanRow && scheduledTrucksRow) {
          Object.keys(totalPlanRow).forEach((columnKey) => {
            if (columnKey !== "key" && columnKey !== "metric") {
              combinedRow[columnKey] =
                (totalPlanRow[columnKey] || 0) - (scheduledTrucksRow[columnKey] || 0);
            }
          });
        }
      } else {

        bevDataSource.forEach((bevRow) => {
          if (
            (metricKey === "totalPlan" && bevRow.key.includes("totalSlots")) ||
            bevRow.key.includes(metricKey)
          ) {
            Object.keys(bevRow).forEach((columnKey) => {
              if (columnKey !== "key" && columnKey !== "metric") {
                combinedRow[columnKey] = (combinedRow[columnKey] || 0) + (bevRow[columnKey] || 0);
              }
            });
          }
        });
  
        iceDataSource.forEach((iceRow) => {
          if (
            (metricKey === "totalPlan" && iceRow.key.includes("totalSlots")) ||
            iceRow.key.includes(metricKey)
          ) {
            Object.keys(iceRow).forEach((columnKey) => {
              if (columnKey !== "key" && columnKey !== "metric") {
                combinedRow[columnKey] = (combinedRow[columnKey] || 0) + (iceRow[columnKey] || 0);
              }
            });
          }
        });
      }
  
      summaryRows.push(combinedRow);
    });
  
    return summaryRows;
  };

  const summaryDataSource = generateSummaryDataSource(bevDataSource, iceDataSource);

  const createEmptyRow = (key: string): TableRow => ({
    key,
    metric: "",
    ...bevColumns.reduce((acc, col) => {
      acc[col.dataIndex as string] = "";
      return acc;
    }, {} as Record<string, any>),
  });

  const combinedDataSource = [
    ...bevDataSource, 
    createEmptyRow("divider-bev"),
    ...iceDataSource, 
    createEmptyRow("divider-ice"),
    ...summaryDataSource
  ];

  const rowClass = (record: TableRow) => record.key.includes("divider") ? "divider-row" : "";

  return (<>

      <style>
        {`
          .ant-table-thead tr th {
            background: #eee !important;
            height: 10px;
            pointer-events: none;
          }

          .ant-table-cell-row-hover {
            background: #F0F8FF !important;
          }

          .ant-table-cell-row-hover.quarter-column {
            background: #E0FFFF !important;
          }

          .ant-table-cell-row-hover.total-column {
            background: #E0FFFF !important;
          }

          .divider-row {
            background: #eee;
            height: 10px;
            pointer-events: none;
            .ant-table-cell {
              background: #eee;
            }
          }

          .divider-row .ant-table-cell-fix-left {
            background: #eee;
            height: 10px;
            pointer-events: none;
          }

          .quarter-column {
            background: #89CFF0;
          }

          .total-column {
            background: lightblue;
          }

          .ant-table-thead {
            .quarter-column {
              background: #89CFF0 !important;
            }
            .total-column {
              background: lightblue !important;          
            }
          }

        `}
      </style>
      {!!statistics && !!line && !!endDate && <>
        <Title level={3}>{line === ALL ? "All Lines" : `Line ${line?.split('_').pop()}`}</Title>
        <Table
          bordered
          columns={bevColumns}
          dataSource={combinedDataSource}
          pagination={false}
          rowClassName={rowClass}
          size="small"
        />
      </>}
  </>);
}
