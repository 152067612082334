import {  Button, Form, Input, message,  notification, Table, Select, Space, Result, Descriptions, FormProps, FormListFieldData } from "antd";
import Title from "antd/lib/typography/Title";
import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, DealerListContext, DealerListContextType } from "../../context";
import { BaseQuote, Customer, Dealer, SalesTeam, } from "../../api/models";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {useIntl} from "react-intl";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";
import CustomerEntry from "../../components/customer_entry";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {SortOrder} from "antd/es/table/interface";
import useCheckMobileScreen from "../../hook/useCheckMobileScreen";
import { debounce } from "lodash";
import BMButton, { BMButtonProps } from "../../components/BMButton";
import ModalWizard from "../../components/ModalWizard";
import { PlusOutlined } from "@ant-design/icons";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../../components/Wizard";
import SelectSalesTeamButton, { useSelectSalesTeamStep } from "../../components/SelectSalesTeamButton";
import SelectQuoteBySalesTeamList, { QuoteSelection } from "../../components/SelectQuoteBySalesTeamList";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import {closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import validator from 'validator';
import { useUsersContext } from "../../contexts/UsersContext";

type SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface DealerFilter {
  search?: string
}
const DEFAULT_PAGE_SIZE = 20;

function isNewDealerRequest(vt: DealerFormValues | undefined) : boolean {
  if ( !vt ) return false;
  return !('id' in vt) || vt.id === undefined;
}

const DealerList = () => {

  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const [_dealerLst, dealerLstAsync] = useAsyncState<Dealer[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [filter, setFilter] = useState<DealerFilter>({
    search: searchFilterParam || undefined, //silly fix for null
  });

  const defaultSort = {
    columnKey: "name",
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<SortResult<Dealer>>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  //change in filters should trigger refetch
  useEffect(() => {
    reloadDealers();
  }, [ filter, sort  ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const reloadDealers = () => {
      loadDealers( dealerLstAsync, pagination, filter , sort );
  }

  const loadDealers = useCallback(debounce( async (dealerLstAsync:AsyncState<Dealer[]>, pagination: TablePaginationConfig, filter: DealerFilter, sorter:SortResult<Dealer> ) => {

    var sort = [ sorter ].flat()[0];
    dealerLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchDealerList({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'descend' ? 'desc' : 'asc',
        }
      })
      dealerLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Sales Teams failed to load. " + errorMsg});
      dealerLstAsync.setFail( e.message );
    }

  }, 400 ), [] );


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SortResult<Dealer>) => {

    if ( sorter != sort ) {
      const firstSort = [ sort ].flat()[0];
      setSortFieldQueryParam( firstSort.columnKey?.toString() );
      setSortDirectionQueryParam( firstSort.order );
      setSort(sorter);
      setCurrentPageParam(1);
      setPagination({
        current: 1,
        ...pagination
      });
    }
    else {
      setPagination(pagination);
    }
  };



  const handleClickNew = () => {
    reloadDealers();
  };

  const handleEdit= () => {
    reloadDealers();
  }

  const handleChangeSearch = (search:string) => {
    setSearchFilterParam(search);
    setFilter({
      search
    });
  }


  const columns: ColumnType<Dealer>[] = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, dealer) => (
        <EditDealerButtonModal type="link" style={{padding: "0"}} onChange={handleEdit} value={dealer}>{name}</EditDealerButtonModal>
      ),
      sorter: (a,b) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ),
      defaultSortOrder: "ascend"
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContactName",
    },
    {
      title: "Sales Teams",
      key: "Sales Teams",
      render: (d:Dealer) => d.salesTeams?.map(st => st.sales?.find(v=>v)?.name ).join(", ")
    },
    {
      title: "Parent Dealer",
      dataIndex: "parentDealer",
      render: (parentDealer:Dealer | undefined) => parentDealer?.name,
    }
  ];


  return <div className="site-layout-background">
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>

      <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
        <Title level={2}>Dealers</Title>
        <EditDealerButtonModal type="primary" onChange={handleClickNew} >
          New
        </EditDealerButtonModal>
      </div>

      <div style={{width: "100%"}}>
        <Input defaultValue={searchFilterParam || undefined} onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" allowClear />
      </div>

      <Table
        loading={dealerLstAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={dealerLstAsync.val}
        columns={columns}
        rowKey="id"
      />

    </Space>

  </div>
};

const EditDealerButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?: Dealer | undefined
  onChange?:(d?:Dealer | undefined) => void
  onCancel?:() => void
}) => {

  const {value:a, onChange:b, ...btnProps} = props;

  const intl = useIntl();

  const configurator = useContext(ConfiguratorContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleteDealer, setIsDeleteDealer] = useState<boolean>(false);
  const [isUpdateQuotes, setIsUpdateQuotes] = useState<boolean>(false);
  const [_dealer, dealerAsync] = useAsyncState<Dealer>();
  const {userLstAsync, loadUserList} = useUsersContext();

  const [_activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const deleteDealer = async (dealerId:string) => {

    dealerAsync.setLoading();
    try {
      await configurator.api.deleteDealer(dealerId);
      message.success("Dealer deleted");
      dealerAsync.setDone(undefined);

      props.onChange?.();
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to delete dealer. " + errorMsg });
      dealerAsync.setFail(e.message);
    }

  }

  const handleDealerChange = async (dealer:Dealer | undefined) => {
    setIsDeleteDealer(false);
    setIsUpdateQuotes(false);

    if ( !dealer ) {
      setIsDeleteDealer(true);
      setTimeout(() => wizardInstance.current?.nextStep(), 100 );
    }
    else {

      const isEditDealer = !!props.value;
      setIsUpdateQuotes(isEditDealer);
      
      const savedDealer = await handleSave( dealer );
      if ( savedDealer ) {

        if ( isEditDealer ) {
          setTimeout(() => wizardInstance.current?.nextStep(), 100 );
        }
        else {
          //done
          setIsOpen(false);
        }
      }
    }
  }

  const handleDelete = async () => {
    const dealerId = props.value?.id;
    if ( !dealerId ) return;

    await deleteDealer(dealerId);
    setIsOpen(false);
  }

  const handleSave = async (dealer:Dealer) : Promise<Dealer | undefined> => {
    try {

      const savedDealer = await saveDealer( dealer );
      if ( savedDealer ) {

        notification.success({message:"Dealer saved."});
        props.onChange?.(savedDealer);
        return savedDealer;

      }
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      const errs = validationErrors.errorFields.map( f => f.errors ).flat().map( (msg, ndx) => <li key={`validationError-${ndx}`}>{msg}</li> );
      const errorMsg = !errs.length ? "Please fix validation errors." : <ul>{errs}</ul>;
      notification.error({message: errorMsg });
    }

    return;
  }

  const buildSalesTeamDto = (st:SalesTeam) => ({
        id: st.id,
        name: st.name,
        sales: st.sales?.map( u => u.id ),
        engineers: st.engineers?.map( u => u.id ),
        support: st.support?.map( u => u.id ),
        viewers: st.viewers?.map( u => u.id ),
      });

  const saveDealer = async (values: DealerFormValues) : Promise<Dealer | undefined> => {


    const salesTeams = values.salesTeams?.map(buildSalesTeamDto);

    const dto = {
      name: values.name,
      primaryContactId: values.primaryContact?.id,
      parentDealerId: values.parentDealerId,
      salesTeams: salesTeams,
      externalContacts: values.externalContacts
    }

    dealerAsync.setLoading();

    try {
      const resp = !isNewDealerRequest(values ) 
        ? await configurator.api.saveDealer(values.id!, dto)
        : await configurator.api.createDealer(dto);
        dealerAsync.setDone( resp.data );

        return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error saving dealer.  " + errorMsg });
      dealerAsync.setFail(e.message);
    }

    return;
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    />
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      steps={[
        useDealerFormSteps({
          key:1,
          value: props.value, 
          onChange: handleDealerChange,
          onCancel: handleCancel
        }),
        {
          key:2,
          hidden: !isDeleteDealer,
          body: () =>  <div key="confirmStep" >
            <ConfirmDeleteDealerResult value={props.value} />
          </div>,
          footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button type="primary" danger onClick={() => handleDelete()}>Delete</Button>
            <Button onClick={() => nav.prevStep()}>Back</Button>
          </div>
        },
        useUpdateDealerQuoteSalesTeamsStep( {
          key:3,
          hidden: !isUpdateQuotes,
          dealerId:props.value?.id,
          primarySalesId:dealerAsync.val?.salesTeams?.find(v=>v)?.sales?.find(v=>v)?.id,
          onChange: handleCancel,
          onCancel: wizardInstance.current?.prevStep
        }),
      ] } 
    />
  </>;
}

export const ConfirmDeleteDealerResult = (props:{
  value:Dealer | undefined
}) => {
  return  <Result
    status="warning"
    title="The following dealer will be deleted:"
    style={{padding: "0"}}
  >
    <Descriptions
      column={1}
      className="dialog-salesTeam"
      items={ [ {
        label: "Name",
        children: props.value?.name
      },
        {
          label:"Sales Teams",
          children: props.value?.salesTeams?.map(st => st.name || st.sales?.find(v=>v)?.name ).join(", " )
        },
        {
          label:"Primary Contact",
          children: props.value?.primaryContactName
        },
        {
          label:"Parent Dealer",
          children: props.value?.parentDealer?.name
        }
      ]}
      size="small"
    />
  </Result>

}


export interface DealerFormValues {
  id?: string
  name?:string
  salesTeams?:SalesTeam[]
  primaryContact?:Customer
  parentDealerId?:string
  externalContacts?:string[]
}
  interface DealerFormSelectedSalesTeam {
    salesTeam:SalesTeam | undefined
    onChange: (s:SalesTeam | undefined) => void
  }

export const useDealerFormSteps = (props: {
  key: React.Key
  hidden?: boolean
  value: Dealer | undefined
  onChange?:(t:Dealer | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const {dealerLstAsync} = useContext<DealerListContextType>(DealerListContext);
  const dealerLst = dealerLstAsync?.val;
  const [selectedSalesTeam, setSelectedSalesTeam] = useState<DealerFormSelectedSalesTeam | undefined>();
  //const [isNewSalesTeam, setIsNewSalesTeam] = useState<boolean>(false);
  //const [isEditSalesTeam, setIsEditSalesTeam] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    loadCustomer(props.value?.primaryContactId);
  }, [props.value?.id])

  const [activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const [customer, customerAsync] = useAsyncState<Customer>();
  const loadCustomer = async (id:number | undefined) : Promise<Customer | undefined> => {
    if (!id) return;

    customerAsync.setLoading();

    try {
      const resp =  await configurator.api.fetchCustomer(id)
      customerAsync.setDone( resp.data );

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error saving customer.  " + errorMsg });
      customerAsync.setFail(e.message);
    }

    return;
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      props.onChange?.(values);
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      const errs = validationErrors.errorFields.map( f => f.errors ).flat().map( (msg, ndx) => <li key={`validationError-${ndx}`}>{msg}</li> );
      const errorMsg = !errs.length ? "Please fix validation errors." : <ul>{errs}</ul>;
      notification.error({message: errorMsg });
    }
  }

  const handleDelete = () => {
    props.onChange?.(undefined);
  }

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleNewSalesTeam = (salesTeam:SalesTeam | undefined, onChange:(s:SalesTeam | undefined) => void) => {
    //setIsNewSalesTeam(true);
    setSelectedSalesTeam({salesTeam, onChange});
    setTimeout(() => wizardInstance.current?.nextStep(), 100 );
  }
  const handleEditSalesTeam = (salesTeam:SalesTeam | undefined, onChange:(s:SalesTeam | undefined) => void) => {
    //setIsEditSalesTeam(true);
    setSelectedSalesTeam({salesTeam, onChange});
    setTimeout(() => wizardInstance.current?.nextStep(), 100 );
  }

  const handleCancelSelectSalesTeam = () => {
    resetSelectSalesTeam();
  }

  const handleSalesTeamSelected = (salesTeam:SalesTeam | undefined) => {
    if (selectedSalesTeam) {
      selectedSalesTeam.onChange(salesTeam);
    }
    resetSelectSalesTeam();
  }

  const resetSelectSalesTeam = () => {
    setSelectedSalesTeam(undefined);
    //setIsNewSalesTeam(false);
    //setIsEditSalesTeam(false);
    wizardInstance.current?.resetSteps();
  }

  const steps = [
    {
      key: 1,
      title: props.value ? `Dealer - ${props.value?.name}` : "New Dealer",
      body: (_nav:WizardInstance) => <DealerForm
        labelCol={{ span: 8 }}
        form={form}
        dealerLst={dealerLst}
        onNewSalesTeam={handleNewSalesTeam}
        onEditSalesTeam={handleEditSalesTeam}
        initialValues={{
          ...(props.value || {}),
          primaryContact: customer,
          parentDealerId: props.value?.parentDealer?.id}}
      />,
      footer: () => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
          <Button key="next" type="primary" onClick={handleSave} >Save</Button>
        </Space>
        <Button key="reset" onClick={handleDelete}>Delete</Button>
      </div>
    },
    useSelectSalesTeamStep( {
      key:2,
      value: selectedSalesTeam?.salesTeam,
      filter: { 
        isUserSalesTeam: true
      },
      onChange: handleSalesTeamSelected,
      onCancel: handleCancelSelectSalesTeam,
    }),
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: () => 
      <Wizard 
        key="editDealer"
        steps={steps} 
        showSteps={false}
        onStep={handleStep}
      />,
    title: () => title,
    footer: () => footer,
  }

}

export const DealerForm = (props:FormProps & {
  dealerLst: Dealer[] | undefined
  onNewSalesTeam: (salesTeam:SalesTeam | undefined, onChange:(s:SalesTeam | undefined) => void) => void
  onEditSalesTeam:  (salesTeam:SalesTeam | undefined, onChange:(s:SalesTeam | undefined) => void) => void
}) =>  {

  const {dealerLst, onNewSalesTeam: handleNewSalesTeam, onEditSalesTeam: handleEditSalesTeam, ...formProps} = props;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (fields:FormListFieldData[], move: (from: number, to: number) => void ) => {

    return (event:DragEndEvent) => {
      const {active, over} = event;

      //do nothing if over is null/undefined
      if ( !over ) return;

      //if same spot do nothing
      if (active.id === over.id) return;

      const oldIndex = fields.findIndex(f => f.key == active.id);
      const newIndex = fields.findIndex(f => f.key == over.id);
      move( oldIndex, newIndex );
    }
  }

  const validateEmail = (_: any, value: string[] | undefined) => {
    if (value?.every(email => validator.isEmail(email))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('One or more email addresses are invalid.'));
  };


  return <Form {...formProps} >
        <Form.Item
          name="id"
          hidden={true}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Primary Contact"
          name="primaryContact"
        >
          <CustomerEntry />
        </Form.Item>

        <Form.Item
          label="Parent Dealer"
          name="parentDealerId"
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            options={dealerLst?.map(pd => ({ value: pd.id, label: pd.name, }))}
          />
        </Form.Item>

        <Form.Item
          label="External Contacts"
          name="externalContacts"
          rules={[
            { validator: validateEmail }
          ]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Press Enter to add email"
          />
        </Form.Item>

        <Form.List name="salesTeams"
          rules={[{ 
            validator: async (_rule, value) => {
              if ( !value?.length ) {
                throw new Error("A Sales Team is required.");
              }
            },
            message: "A Sales Team is required" }]}
        >
          {(fields, {add, remove, move}, _meta) => <>
            {fields.length === 0 
              ? <Form.Item label={"Sales Teams"} colon={false} key="salesTeam-0" >
                <Button icon={<PlusOutlined/>} type="primary" shape="circle" size="small" onClick={() => handleNewSalesTeam(undefined, (s) => add(s))} />
              </Form.Item>
              : <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd(fields, move)}
              >
                <SortableContext 
                  items={fields.map(f => f.key.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map((fld, ndx) =>  {
                    const fldName = ["salesTeams", fld.name];
                    const first = (ndx === 0 );
                    const lbl = first ? "Sales Team" : <></>;
                    const handleAdd = first ?() => handleNewSalesTeam(undefined, (s) => s && add(s)) : undefined;
                    return <SortableItem key={fld.key.toString()} id={fld.key.toString()} >
                      <div key={"salesTeamContainer" + ndx}>
                        <Form.Item label={lbl} name={fld.name} colon={false}  key={"salesTeam-" + ndx}>
                          <SelectSalesTeamButton onClick={(salesTeam) => handleEditSalesTeam(salesTeam, (s) => s ? props.form?.setFieldValue( fldName, s) : remove(fld.name) )} onAdd={handleAdd} />
                        </Form.Item>
                      </div>
                    </SortableItem>
                  })}
                </SortableContext>
              </DndContext>}
          </>}
        </Form.List>

        <div style={{fontStyle: "italic", textAlign: "center"}}>Note: The primary sales team is listed first.</div>
      </Form>;

}


const useUpdateDealerQuoteSalesTeamsStep = (props:{
  key: React.Key
  hidden?: boolean
  dealerId: string | undefined
  primarySalesId: string | undefined
  onChange?:(q?:number[] | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [quoteLst, setQuoteLst] = useState<QuoteSelection>();
  const [_updateQuotes, updateQuotesAsync] = useAsyncState<BaseQuote[]>([]);

  const updateQuotes = async (dealerId:string, values: number[] | undefined) : Promise<void> => {

    updateQuotesAsync.setLoading();
    try {
      await configurator.api.refreshDealerQuoteSalesTeams(dealerId, values );
      updateQuotesAsync.setDone();
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error updating quotes.  " + errorMsg });
      updateQuotesAsync.setFail(errorMsg);
    }

    return;
  };

  const handleUpdateQuotes = async (nav:WizardInstance) => {
    if ( !props.dealerId ) return;

    if ( quoteLst?.selections.length ) {

      const lst = quoteLst?.type === "all" ? undefined : quoteLst?.selections;
      await updateQuotes(props.dealerId, lst);

      nav.nextStep();
      props.onChange?.(lst);
    }
    else {
      nav.nextStep();
      props.onChange?.([]);
    }

  }

  const handleCancel = (nav:WizardInstance) => {

    nav.nextStep();
    props.onCancel?.();
  }

  return {
    key: props.key,
    hidden: props.hidden,
    title: "Update Quotes",
    body: () =>  <div key="quoteUpdate" >
      <div style={{marginBottom: ".4rem"}}>Select quotes to update with latest sales team.</div>
      <SelectQuoteBySalesTeamList 
        dealerId={props.dealerId} 
        onChange={setQuoteLst}
      />
    </div>,
    footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Button type="primary" loading={updateQuotesAsync.isLoading()} onClick={() => handleUpdateQuotes(nav)}>Update</Button>
      <Button onClick={() => handleCancel(nav)}>Back</Button>
    </div>

  };
}

export const SortableItem = (props:{
  id:string
  children:ReactElement
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}




export default DealerList;

