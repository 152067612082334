import {useEffect} from "react";
import { useUsersContext } from "../contexts/UsersContext";
import UserMultiSelector, {UserMultiSelectorProps} from "./UserMultiSelector";

const QuoteEngineerSelector = (props: Omit<UserMultiSelectorProps, "value" | "onChange"> & {
  value?:string[]
  onChange?: (users: string[]) => void;
}) => {

  const {userLstAsync, loadUserList} = useUsersContext();

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = userLstAsync?.val?.filter( u => !u.dealerId );

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}

export default QuoteEngineerSelector;

