import { useEffect } from "react";
import { Select } from "antd";
import { User } from "../../api/models";
import { useUsersContext } from "../../contexts/UsersContext";


const UserSingleSelector = (props: {
  onChange?: (users: User | undefined) => void;
  value?: User;
  userLst?: User[];
  style?: any;
}) => {

  const {userLstAsync, loadUserList} = useUsersContext();

  useEffect(() => {
    if ((userLstAsync?.isInitial() || userLstAsync?.isFail()) && !userLstAsync.isLoading()) {
      loadUserList?.();
    }
  }, []);

  const { userLst: propUserLst, onChange, value, style } = props;
  const userLst = propUserLst || userLstAsync?.val;
  const options = userLst?.sort((a, b) => a.name.localeCompare(b.name)).map(c => ({ label: c.name, value: c.id }));

  return <Select
    style={style}
    onChange={(id) => {
      const selected = userLst?.find(u => u.id === id);
      onChange?.(selected);
    }}
    showSearch
    optionFilterProp="label"
    value={value?.id}
    allowClear
    options={options}
  />

};

export default UserSingleSelector;
