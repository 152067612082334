import {useState} from "react";
import { CustomOptionType} from "../api/models";
import BMButton, { BMButtonProps } from "./BMButton";
import { Form } from "antd";
import ModalWizard from "./ModalWizard";
import { useForm } from "antd/es/form/Form";
import useCustomOptionWizardSteps from "./useCustomOptionWizardSteps";

export const EditCustomOptionButtonModal = (props: Omit<BMButtonProps, 'onChange' | 'value'> & {
  value?:CustomOptionType | undefined
  onChange:(co:CustomOptionType) => void
  categoryId?: number
}) => {

  const {value:a, onChange:b, categoryId:c, ...btnProps} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleChange = (co:CustomOptionType) => {
    setIsOpen(false)
    props.onChange(co);
  }

  const {initialValues, handleValuesChange, steps, hasMetadataPermissions} = useCustomOptionWizardSteps( {
    ...props,
    isOpen,
    onCancel:handleCancel,
    onChange:handleChange,
    form:form,
  })

  const titleLbl = props.value?.content?.length ? "Edit " + props.value.content : "Add Custom Option";

  return <>
    <BMButton type="primary"
      {...btnProps}
      onClick={handleOpen}
    >{props.children}</BMButton>
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
    >
      <ModalWizard
        width={"50rem"}
        title={titleLbl}
        open={isOpen}
        onCancel={handleCancel}
        showSteps={true}
        steps={steps}
        viewHeight="515px"
      />
    </Form>
  </>

}

export default EditCustomOptionButtonModal;

