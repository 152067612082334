import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { AXIOS_CANCEL_MSG, BaseCategory, DEFAULT_THROTTLE } from "../api/models";
import { notification } from "antd";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";
import { throttle } from "lodash";
import axios, {CancelTokenSource} from "axios";

export interface BaseCategoryContextType {
  categoriesAsync?: AsyncState<BaseCategory[]>
  loadCategories?:(modelId?:number)=>Promise<BaseCategory[] | undefined>
}
const BaseCategoryContext = React.createContext<BaseCategoryContextType>({});

export const useBaseCategoryContext = () : BaseCategoryContextType => {
    return useContext(BaseCategoryContext);
}

// Data provider component
const BaseCategoryContextProvider = (props:{children: ReactElement | ReactElement[] }) => {

  const [_categories, categoriesAsync] = useAsyncState<BaseCategory[]>([]);

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const context = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    reloadCategories();
  }, []);

  const reloadCategories = async () : Promise<BaseCategory[] | undefined> => loadCategories(categoriesAsync);
  
  const loadCategories = useCallback(throttle( async (categoriesAsync:AsyncState<BaseCategory[]>) : Promise<BaseCategory[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    categoriesAsync.setLoading();
    try {
      const resp = await context.api.getBasicCategories(cancelSource.token);
      //most of the time we want them sorted by name

      const categoryLst = resp.data.sort((a, b) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ) );
      cancelTokenSourceRef.current = undefined;

      categoriesAsync.setDone( categoryLst );
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Categories failed to load. " + errorMsg });
        categoriesAsync.setFail(e.message);
      }
    }
    return;
  }, DEFAULT_THROTTLE), []);

  const categoryContext = useMemo<BaseCategoryContextType>( () => ({
    categoriesAsync, 
    loadCategories:reloadCategories
  }) , [ categoriesAsync ]);

  return <BaseCategoryContext.Provider value={categoryContext}>{props.children}</BaseCategoryContext.Provider>;
};
  
export default BaseCategoryContextProvider;



