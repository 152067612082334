import { useEffect } from "react";
import { Select, SelectProps, } from "antd";
import { User } from "../api/models";
import { useUsersContext } from "../contexts/UsersContext";
import Utils from "../util/util";

export interface UserMultiSelectorProps extends SelectProps {
  value?:User[]
  onChange?: (users: User[]) => void;
  userLst?:User[]
}

const UserMultiSelector = (props: UserMultiSelectorProps) => {

  const {userLstAsync, loadUserList} = useUsersContext();

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const { userLst:propUserLst, ...selectProps} = props; 
  const selectedIdLst = props.value?.map(u => u.id );
  const userLst = [
    ...(props.value || [] ),
    ...( ( propUserLst || userLstAsync?.val )?.filter( u => !selectedIdLst?.includes(u.id) ) || [] ) 
  ];

  const value = props.value?.map( u => u.id );
  const options = userLst?.sort((a,b) => a.name.localeCompare(b.name)).map(c => ({label: Utils.formatUsername( c ), value: c.id}));

  return <Select 
    {...selectProps}
    onChange={(idLst) => {
      const selected = idLst.map(id => userLst?.find(u => u.id === id ) );
      props.onChange?.( selected );
    }}
    showSearch
    optionFilterProp="label" 
    mode={"multiple"}
    value={value}
    allowClear
    options={options}
  />

};

export default UserMultiSelector;
