import {Button, Drawer, notification, Result, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Quote, QuoteReview } from "../../api/models";
import {ConfiguratorContext} from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import StatesSelector from "../StatesSelector";
import { WizardInstance } from "../Wizard";
import { useQuoteContext } from "../../contexts/QuoteContext";
import DocumentSignUI from "../DocusignUI";
import { useRequestPoSteps, RequestPurchaseOrderFormValue } from "../RequestPoModalButton";
import { RequestPurchaseOrder } from "../../api";


const SubmitDocusignOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync, setQuoteFormValues } = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [docusignLoading, setDocusignLoading] = useState<boolean>(false);
  const [selectedStateRegistration, setSelectedStateRegistration] = useState<string>();
  const [purchaseOrderRequest, setPurchaseOrderRequest] = useState<RequestPurchaseOrder>();

  useEffect(() => {
    if ( isOpen ) { 
    }
  }, [quote, isOpen])

  const isValidStateRegistration = (v:string | undefined) : boolean => {
    return !!v && v !== 'TBD';
  }

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView: false});
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }


  const addTrucks = async (quoteId:string | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId) return;

    quoteAsync?.setLoading();
    try {
      const resp = await configurator.api.addTrucks(quoteId);
      quoteAsync?.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      quoteAsync?.setFail(e.message);
    }
    return;
  }

  const requestPurchaseOrder = async (quoteRevisionId: number | undefined, req:RequestPurchaseOrderFormValue) : Promise<void> => {
    if ( !quoteRevisionId) return;

    setDocusignLoading(true);
    try {
      await configurator.api.requestPo(quoteRevisionId, req)
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
    }
    setDocusignLoading(false);
  }


  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOpen = async () => {
    if (!quote) return;

    await loadReview(quote?.displayRevisionId);

    setSelectedStateRegistration(quote?.stateRegistration);

    setIsOpen( true );
  }

  const handleSelectStateRegistration = async (nav:WizardInstance) => {
    if (!quote) return;

    if ( quote.stateRegistration !== selectedStateRegistration ) {
      await setQuoteFormValues?.({
        ...quote,
        stateRegistration: selectedStateRegistration
      });
    }

    await addTrucks(quote.quoteId);

    nav.nextStep();
  }

  const handleFinished = async (formValues:RequestPurchaseOrderFormValue) => {
    setIsOpen(false);

    //dealers sign the PO in-app
    const isDealer = configurator.isDealerSales();
    if ( isDealer ) {
      setPurchaseOrderRequest(formValues)
      setShowDrawer(true);
    }
    else {
      await requestPurchaseOrder( quote?.displayRevisionId, formValues );

      submit();
    }
  }

  const handleDoneSigning = () => {
    setShowDrawer(false);
    submit();
  }

  const handleSkipPo = () => {
    submit();
  }

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.submitOrder(quote.displayRevisionId);
      quoteAsync.setDone(resp.data);

      notification["success"]({
        message: "Submitted",
      });

      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit order. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };

  const isWorking = !!props.loading || quoteAsync?.isLoading() || reviewAsync.isLoading() || docusignLoading || showDrawer;

  const {steps: docusignSteps} = useRequestPoSteps({
    loading: isWorking,
    onFinished: handleFinished,
    onSkip: handleSkipPo
  })

  return <>
    <BMButton
      type="primary"
      onClick={handleOpen}
      {...btnProps}
      loading={isWorking}
    >
      Submit Order
    </BMButton>
    <ModalWizard
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      steps={[
        {
          key:"review",
          hidden: !Utils.reviewHasErrors(review),
          body: (_nav) => <Result status="warning" 
            title={"The following issues with the order have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"stateRegistration",
          body:(_nav) => <div>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
            <Title level={5}>Is the state of registration correct?</Title>
              <StatesSelector data-testid="submitStateRegistrationSelector" includeTbd={false} allowClear={false} value={selectedStateRegistration} 
                onChange={setSelectedStateRegistration} 
                style={{width: "10rem"}}
              />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!isValidStateRegistration(selectedStateRegistration)} loading={isWorking} onClick={() => handleSelectStateRegistration(nav)} >Next</Button>
            <Button key="back" type="primary" onClick={() => nav.prevStep() } >Back</Button>
          </div>,
        },
        ...docusignSteps,
      ]}    
    />
    <Drawer
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={"100%"}
      destroyOnClose={true}
    >
      <Spin spinning={drawerLoading} tip="One moment.  Preparing your order." >
        <div style={{display: "flex", justifyContent: "center", padding: "1rem"}}>
          <DocumentSignUI quoteRevisionId={quote?.displayRevisionId} details={purchaseOrderRequest} onDone={handleDoneSigning} onLoad={(l) => setDrawerLoading(l) } />
        </div>
      </Spin>

    </Drawer>
  </>
}


export default SubmitDocusignOrderButton;
