import "../util/mobile-table.css";
import Title from "antd/lib/typography/Title";
import { 
  Image,
  Input,
  Table,
  Space,
  notification,
  Row,
  Col,
  Form,
  Button,
  Upload,
  Checkbox,
  Card,
  UploadProps,
  UploadFile,
  Select,
  Modal,
} from "antd";
import  { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { SortDirection, AXIOS_CANCEL_MSG, ImageAsset, DEFAULT_THROTTLE, MISSING_IMAGE, } from "../api/models";
import { FilterValue, SorterResult, TablePaginationConfig, SortOrder } from "antd/lib/table/interface";
import {throttle } from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import axios, { CancelTokenSource } from "axios";
import { useForm, useWatch } from "antd/es/form/Form";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import _ from "lodash";
import { SaveImageAsset } from "../api";

type ImageAssetSort = SorterResult<ImageAsset> | SorterResult<ImageAsset>[]

interface ImageAssetFilter {
  search?: string
}

const NOT_FOUND = -1;

const DEFAULT_FILTER = {
}

const DEFAULT_PAGE_SIZE = 50;
const ImageLibraryPage = () => {
  const intl = useIntl();

  const [imageAssetLst, imageAssetLstAsync] = useAsyncState<ImageAsset[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [editImageAsset, setEditImageAsset] = useState<ImageAsset | undefined>();
  const [showEditImageAsset, setShowEditImageAsset] = useState<boolean>(false);

  const imageAssetLstCancelTokenSourceRef = useRef<CancelTokenSource>();

  const [filter, setFilter] = useState<ImageAssetFilter>({
    ...DEFAULT_FILTER,
    search: searchFilterParam || undefined, //silly fix for null
  });

  const defaultSort = {
    columnKey: "filename",
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<ImageAssetSort>( defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);

    reloadImageAssets();
    return () => imageAssetLstCancelTokenSourceRef.current?.cancel(AXIOS_CANCEL_MSG);
  }, [pagination.pageSize, pagination.current, filter, sort]);

  const reloadImageAssets = async () => loadImageAssets(imageAssetLstAsync, pagination, filter, sort);
  
  const loadImageAssets = useCallback(throttle( async ( imageAssetLstAsync:AsyncState<ImageAsset[]>, pagination: TablePaginationConfig, filter:ImageAssetFilter | undefined, sorter:ImageAssetSort ) => {

    if ( imageAssetLstCancelTokenSourceRef.current ) {
      imageAssetLstCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    imageAssetLstCancelTokenSourceRef.current = cancelSource;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || defaultSort.columnKey,
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));


    imageAssetLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchImageAssets({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token
      )

      imageAssetLstCancelTokenSourceRef.current = undefined;

      imageAssetLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Report failed to load. " + errorMsg });
        imageAssetLstAsync.setFail(e.message);
      }
    }

  }, DEFAULT_THROTTLE ), [] );

  const handleShowAddImageAsset = () => {
    setEditImageAsset(undefined);
    setShowEditImageAsset(true);
  }

  const handleAddedImageAsset = (imageAsset:ImageAsset) => {
    const lst = [ imageAsset ].concat( imageAssetLst || [] );
    imageAssetLstAsync.setDone( lst );

    setShowEditImageAsset(false);
    setEditImageAsset(undefined);
  }

  const handleCancelEditImageAsset = () => {
    setShowEditImageAsset(false);
    setEditImageAsset(undefined);
  }
  const handleEditedImageAsset = (imageAsset:ImageAsset) => {

    if ( !imageAsset.id ) {
      handleAddedImageAsset((imageAsset))
      return;
    }

    const ndx = imageAssetLst?.findIndex( c => c.id === imageAsset.id ) ?? NOT_FOUND;
    if ( ndx === NOT_FOUND ) {
      handleAddedImageAsset((imageAsset))
      return;
    }

    const lst = [...(imageAssetLst || [])];

    if ( imageAsset.hidden ) {
      lst.splice( ndx, 1 );
    }
    else {
      lst.splice( ndx, 1, imageAsset );
    }
    imageAssetLstAsync.setDone( lst );

    setShowEditImageAsset(false);
    setEditImageAsset(undefined);
  }

  const handleEditImageAsset = (imageAsset:ImageAsset) => {
    setEditImageAsset(imageAsset);
    setShowEditImageAsset(true);
  }

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: ImageAssetSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const handleFilterChange = (_changedValues: Record<string, any>, allValues: any) => {

    setSearchFilterParam(allValues.search);

    setFilter({
      ...filter,
      ...allValues,
    });
  }



  return <div className="site-layout-background">

    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      <Title level={2}>Image Library</Title>

      <div style={{display:"flex", flexDirection: "row-reverse", alignItems: "center", paddingLeft: ".5rem" }} >
        <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small" style={{marginLeft: "8px", marginRight: "8px"}}
          onClick={handleShowAddImageAsset}
        />

        <Input
          allowClear
          value={filter.search} 
          placeholder="Search images by keyword" 
          onChange={(e) => handleFilterChange({}, {"search":e.target.value})} 
        /> 

      </div> 

      <Table
        className="mobile-table"
        size="small"
        bordered={false}
        rowKey="id"
        loading={imageAssetLstAsync.isLoading()}
        dataSource={imageAssetLst}
        pagination={pagination}
        onChange={tableOnChange}
        showHeader={false}
        columns={[{ render: (imageAsset) => 
          <ImageAssetCard imageAsset={imageAsset} 
            onEdit={handleEditImageAsset} 
          />
        }]}
      />
    </Space>

    <Modal 
      title="Image Asset"
      open={showEditImageAsset}
      footer={null}
      onCancel={handleCancelEditImageAsset}
    >
      <EditImageAssetCard 
        imageAsset={editImageAsset}
        onCancel={handleCancelEditImageAsset}
        onSave={handleEditedImageAsset}
      />
    </Modal>

  </div>

};

type ImageAssetForm  =  { 
  id?:number
  internal?: boolean
  hidden?: boolean
  tags:string[]
  upload: UploadFile[]
}

export function asImageAsset(imageAsset: SaveImageAsset | ImageAsset | undefined) : ImageAsset | undefined {
  return !isNewImageAsset(imageAsset) ? imageAsset as ImageAsset : undefined;
}
export function isNewImageAsset(imageAsset: ImageAsset | SaveImageAsset | undefined) : boolean {
  if ( !imageAsset ) return true;
  return !('id' in imageAsset) || imageAsset.id === undefined;
}

const ImageAssetCard = (props: {
  imageAsset:ImageAsset
  onEdit: (i:ImageAsset) => void
}) => {

  const configurator = useContext(ConfiguratorContext);
  const { imageAsset } = props;

  const handleEditimageAsset = () => {
    props.onEdit( imageAsset );
  }
  const canEdit = ( imageAsset.createdBy?.id === configurator.userInfo?.id || configurator.isAdmin() );

  const backgroundColor = imageAsset.hidden ? "#f9f9f9" : undefined;
  
  const hasEdit = imageAsset.createdAt !== imageAsset.updatedAt || imageAsset.createdBy?.id !== imageAsset.updatedBy?.id;

  return <Card
    headStyle={{minHeight: "auto", padding: ".4rem .4rem .4rem .4rem" }}
    bodyStyle={{padding: ".4rem", backgroundColor }}
    title={<>
      <Row justify={"space-between"}>
        <Col style={{overflowX: "clip", maxWidth: "18rem", fontWeight:"500"}}>
          <div>Keywords: {imageAsset.tags.join(", ")}</div>
        </Col>
        {(hasEdit || imageAsset.internal ) && 
          <Col>
            <span style={{fontStyle: "italic", fontSize: "smaller", }}>
              internal
            </span>
          </Col>}
        {canEdit &&
        <Col>
        <Button size="small" onClick={handleEditimageAsset} shape="circle" icon={<EditFilled />} />
        </Col>}
      </Row>
    </>}
  >

    <div style={{marginTop: ".2rem"}} >
      <Image
        src={imageAsset.url ||  "error"}
        fallback={MISSING_IMAGE}
        style={{paddingBottom: "20px"}}
        preview={false}
        height={200}
      />
    </div>


          
  </Card>;

}


export const EditImageAssetCard = (props: {
  imageAsset:ImageAsset | SaveImageAsset | undefined
  onCancel:() => void
  onSave:(c:ImageAsset) => void
}) => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [form] = useForm();
  const [_imageAsset, imageAssetAsync] = useAsyncState<ImageAsset>();
  const internal = useWatch( 'internal', form );
  const upload = Form.useWatch('upload', form);
  const [initialValues, setInitialValues] = useState<ImageAssetForm>();

  useEffect(() => {
    console.log( initialValues );
    form.resetFields();
  }, [initialValues] );

  useEffect(() => {

    const values = ( props.imageAsset ) 
      ? {
        id: props.imageAsset.id,
        internal: props.imageAsset.internal,
        hidden: props.imageAsset.hidden,
        tags: props.imageAsset.tags,
        upload: [
          { response: {
            assetUri: props.imageAsset.assetUri,
            url: props.imageAsset.url,
            name: props.imageAsset.filename,
            type: props.imageAsset.contentType,
          }}
        ] as UploadFile[],
      }
      : undefined;

      setInitialValues(values);
  }, [props.imageAsset] );

  const createImageAsset = async (imageAssetAsync:AsyncState<ImageAsset>, imageAsset:SaveImageAsset) : Promise<ImageAsset | undefined> => {
    if (!imageAsset) return;

    imageAssetAsync.setLoading()
    try {
      const resp = await configurator.api.createImageAsset(imageAsset);
      imageAssetAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save image. " + errorMsg });
      imageAssetAsync.setFail(e.message);
    };

    return;
  }

  const updateImageAsset = async (imageAssetAsync:AsyncState<ImageAsset>, id:number | undefined, imageAsset:SaveImageAsset | undefined) : Promise<ImageAsset | undefined> => {
    if (!imageAsset) return;
    if (!id) return;

    imageAssetAsync.setLoading()
    try {
      const resp = await configurator.api.updateImageAsset(id, imageAsset)

      imageAssetAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save image. " + errorMsg });
      imageAssetAsync.setFail(e.message);
    };

    return;
  }

  const handleCancel = async () => {
    props.onCancel();
  }
  const handleSetHidden = async (imageAsset:ImageAsset | undefined, hidden:boolean):Promise<ImageAsset | undefined> => {
    if ( !imageAsset?.id ) return;

    const updated = await updateImageAsset( imageAssetAsync, imageAsset.id, { ...imageAsset, hidden } );
    if ( updated ) {
      props.onSave?.(updated);
    }

    return;
  }
  const handleSave = async ():Promise<ImageAsset | undefined> => {

    try {

      const values = await form.validateFields() as ImageAssetForm;
      const imageAsset = {
        ...values,
        upload: undefined,
        assetUri:values.upload?.[0].response.assetUri,
        filename:values.upload?.[0].response.name,
        contentType:values.upload?.[0].response.type,
      }
      const savedimageAsset = await updateImageAsset( imageAssetAsync, values.id, imageAsset ) 
 
      if ( savedimageAsset ) {
        props.onSave(savedimageAsset);
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

    return;
  }

  const handleAdd = async () => {
    try {

      const values = await form.validateFields() as ImageAssetForm;
      const imageAsset = {
        ...values,
        upload: undefined,
        assetUri:values.upload?.[0].response.assetUri,
        filename:values.upload?.[0].response.name,
        contentType:values.upload?.[0].response.type,
      }
      const savedimageAsset = await createImageAsset( imageAssetAsync, imageAsset );
      if ( savedimageAsset ) {
        props.onSave(savedimageAsset);
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

    return;
  }

  const uploadImageUrl = configurator.api.getUploadImageAssetUrl(internal);

  const handleUploadChange: UploadProps['onChange'] = ({ file }) => {
    return [file];
  }

  return <Card style={{marginBottom: ".4rem"}}
    bordered={false}
    bodyStyle={{padding: ".4rem .4rem 1rem .4rem"}}
  >
    <Form 
      initialValues={initialValues}
      form={form}
    >
      <Form.Item name="id" hidden={true}>
        <Input/>
      </Form.Item>

      <div style={{display: "flex", flexDirection: "row-reverse", gap: ".5rem"}}>
      <Form.Item
        name="internal"
        valuePropName="checked"
      >
        <Checkbox>Internal</Checkbox>
      </Form.Item>
      </div>


      <Form.Item
        label="Keywords"
        name="tags"
        rules={[{
          required: true,
          message: "At least one keyword is required."
        }]}

      >
        <Select mode="tags" />
      </Form.Item>


      <Form.Item
        name="upload"
        getValueFromEvent={handleUploadChange}
        valuePropName="fileList"
        noStyle
        rules={[{
          required: true,
          message: "An image is required."
        }]}

      >
        <Upload.Dragger 
          name="upload"
          action={uploadImageUrl}
          withCredentials={true}
          showUploadList={false}
          maxCount={1}
          accept=".gif,.png,.jpg" >
          {upload?.[0]?.response?.url 
            ? <Image
              src={upload[0].response.url}
              fallback={MISSING_IMAGE}
              style={{paddingBottom: "20px"}}
              preview={false}
            />
            : <div style={{ marginTop: 16 }}>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload Document</div>
            </div> }
        </Upload.Dragger>
      </Form.Item>

      <Row justify={"space-between"} gutter={8} style={{marginTop: "1rem"}}>
        <Col>
          {asImageAsset(props.imageAsset)?.hidden 
            ?<Button size="small" onClick={() => handleSetHidden(asImageAsset(props.imageAsset), false)}>Show</Button>
            :<Button size="small" danger onClick={() => handleSetHidden(asImageAsset(props.imageAsset), true)}>Hide</Button>}
        </Col>
        <Col>
            {!isNewImageAsset(props.imageAsset)
              ? <Space>
                <Button size="small" onClick={handleCancel}>Cancel</Button>
                <Button size="small" onClick={handleSave} type="primary">Save</Button>
              </Space>
              : <Space>
                <Button size="small" onClick={handleCancel}>Cancel</Button>
                <Button size="small" onClick={handleAdd} type="primary">Add</Button>
              </Space> }
        </Col>
      </Row>
    </Form>
  </Card>
}

export default ImageLibraryPage;
