import "../util/mobile-table.css";
import styles from './bts.module.css'
import Title from "antd/lib/typography/Title";
import { Input, Table, Space, notification, Descriptions, Button,  Badge, DrawerProps, Drawer, Collapse, Row, Col, Form, DatePicker, Select,  } from "antd";
import  { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { AXIOS_CANCEL_MSG, BtsReport, BTS_REPORT_STATUS, CommentStats, CommentTopic, DateFilterType, PAGINATION_MAX_PAGE_SIZE, QuoteComment, SortDirection } from "../api/models";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {throttle} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import dayjs, { Dayjs } from "dayjs";
import axios, { CancelTokenSource } from "axios";
import { Link} from "react-router-dom";
import { getCSVRow } from "../helpers/csv";
import { DescriptionsItemType } from "antd/es/descriptions";
import CommentActivityList from "../components/CommentActivityList";
import { InfoCircleTwoTone } from "@ant-design/icons";
import useDateTypeAndRangeHook from "../hook/useDateTypeAndRangeHook";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";

const { RangePicker } = DatePicker;

type  BtsReportSort = SorterResult<BtsReport> | SorterResult<BtsReport>[]

interface BtsFilter {
  search?: string
  dateFilterType?: DateFilterType;
  dateFilterStart?: Dayjs;
  dateFilterEnd?: Dayjs;
}

const DEFAULT_PAGE_SIZE = 10;
const BtsPage = () => {
  const intl = useIntl();

  const [btsReportLst, btsReportLstAsync] = useAsyncState<BtsReport[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);

  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);

  const btsReportLstCancelTokenSourceRef = useRef<CancelTokenSource>();
  const [filter, setFilter] = useState<BtsFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
  });
  const [isExporting, setExporting] = useState<boolean>(false);
  const [selectedReport, selectedReportAsync] = useAsyncState<BtsReport>();
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [quoteCommentStats, setQuoteCommentStats] = useState<Record<number,CommentStats>>();

  const showAdvancedPanel = searchFilterParam?.length  ||
    dateFilterTypeParam?.length ||
    dateFilterStartParam?.length ||
    dateFilterEndParam?.length;

  const [filterForm] = useForm();

  const defaultSort = {
    columnKey: "latestRevision.summary.firstTruckProductionDate",
    order: 'descend' as SortOrder
  };
  const [sort, setSort] = useState<BtsReportSort>( defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    if ( btsReportLstAsync.isDone() ) {
      loadQuoteCommmentCnt();
    }
  }, [ btsReportLstAsync ]);


  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);

    reloadBtsReport();
    return () => loadBtsReport.cancel();

  }, [pagination.pageSize, pagination.current, filter, sort]);

  const reloadBtsReport = async () => loadBtsReport(btsReportLstAsync, pagination, filter, sort);
  
  const loadBtsReport = useCallback(throttle( async ( btsReportLstAsync:AsyncState<BtsReport[]>, pagination: TablePaginationConfig, filter:BtsFilter | undefined, sorter:BtsReportSort ) => {

    if ( btsReportLstCancelTokenSourceRef.current ) {
      btsReportLstCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    btsReportLstCancelTokenSourceRef.current = cancelSource;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || defaultSort.columnKey,
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));


    btsReportLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchBtsReport({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token
      )

      btsReportLstCancelTokenSourceRef.current = undefined;

      btsReportLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Report failed to load. " + errorMsg });
        btsReportLstAsync.setFail(e.message);
      }
    }

  }, 1000 ), [] );

  const getCommentCount = async (quoteId:string) : Promise<Record<string, CommentStats>> => {
    const commentLst = await loadQuoteComments(quoteId);
    const total = commentLst?.length || 0;
    const unread = commentLst?.filter(c => !c.lastViewedAt).length || 0;
    return { 
      [quoteId]: { total, unread }
    };
  }

  const updateQuoteCommmentCnt = async (quoteId:string | undefined) => {
    if ( !quoteId ) return;

    const commentLst = await getCommentCount(quoteId);

    if ( commentLst ) {
      setQuoteCommentStats( { 
        ...quoteCommentStats, 
        ...commentLst
      } );
    }
  }

  const loadQuoteCommmentCnt = async () => {
      Promise.all(btsReportLst?.map(r => r.quoteId).map( getCommentCount ) || [])
        .then( qc => {
          const quoteCommentCnt = qc.reduce( ( acc, v ) => ({...acc, ...v}), {}) ;
          setQuoteCommentStats( quoteCommentCnt );
        });
  }

  const loadQuoteComments = async (quoteId:string | undefined) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const topic = [ CommentTopic.BTS ]
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic});

      return resp.data;
    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed to fetch comments " + errorMsg });
      }
    }
    return;
  };

  const updateStatus = async ( reportAsync:AsyncState<BtsReport>, quoteId:string, status:BTS_REPORT_STATUS ) : Promise<boolean | undefined> => {
    if ( !quoteId ) return;

    reportAsync.setLoading();
    try {
      await configurator.api.updateBtsReportStatus( quoteId, status )

      reportAsync.setDone(reportAsync.val);

      return true;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Report failed to status. " + errorMsg });
      reportAsync.setFail(e.message);
    }

    return false;
  };

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: BtsReportSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const handleChangeSearch = (search:string) => {
    setSearchFilterParam(search);
    setFilter({
      ...filter,
      search
    });
  }

  const handleAdvFilterChange = async (_changedValues: Record<string, any>, allValues: any) => {

    setDateFilterTypeParam(allValues.dateFilterType);

    const dateFilterStart = allValues.dateRange && allValues.dateRange?.[0];
    const dateFilterEnd = allValues.dateRange && allValues.dateRange?.[1]
    setDateFilterStartParam(dateFilterStart.format("YYYY-MM-DD"));
    setDateFilterEndParam(dateFilterEnd.format("YYYY-MM-DD"));

    setFilter({
      ...filter,
      ...allValues,
    dateFilterStart,
    dateFilterEnd
    });
  }

  const handleUpdateStatus = (quoteId:string | undefined, status:BTS_REPORT_STATUS) => {
    if ( !quoteId ) return;

    updateStatus( selectedReportAsync, quoteId, status )
      .then( rpt =>  {
        if( rpt ) {
          reloadBtsReport();
        }
      });
  }

  const handleExport = async ( filter:BtsFilter | undefined, sorter:BtsReportSort ) => {
    try {

      const sort = [sorter].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || defaultSort.columnKey,
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setExporting(true);

      const resp = (await configurator.api.fetchBtsReport({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE,
        sort,
      })).data;

      const columns = [
        'VIN', "EstProductionDate", "EstShipDate", 'Quote ID', 'Quantity', 'TruckDescription','EndCustomer','Dealer','Chassis','Body Mfg','Body Model','Fuel' 
      ];
      const headers = getCSVRow(columns);

      const data = resp.content.map((report:BtsReport) => 
        report.trucks.map(t =>
          getCSVRow([ 
            t.vin, 
            t.productionDate ? dayjs(t.productionDate).format("MM-DD-YYYY") : '',
            t.shipDate ? dayjs(t.shipDate).format("MM-DD-YYYY") : '',
            report.quoteId, 
            report.quantity ? String( report.quantity ) : "", 
            report.truckDescription, 
            report.endCustomer?.name || "", 
            report.dealer?.name || "", 
            [report.modelYear, report.chassis].join(" "),
            report.bodyMfg, 
            report.bodyModel, 
            report.fuelType,
            report.status 
          ]))).flat();

      const csv = [ headers, ...data];
      var blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'bts_report-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Export failed. " + errorMsg });
    }

    setExporting(false);

  };

  const handleShowComments = (report:BtsReport) => {
    selectedReportAsync.setDone( report );
    setIsCommentsOpen(true);
  }

  const getDateRange = (filter: BtsFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }
  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(filterForm, "dateFilterType", "dateRange", handleAdvFilterChange);
  const filterWithDateRange = {...filter, dateRange: getDateRange(filter)}

  const BtsStatus = (props:{
    report:BtsReport
  }) => <Space direction="vertical">
    <Button className={styles['open-btn']} size="small" style={{width:"5rem", }} type={props.report.status === BTS_REPORT_STATUS.OPEN ? "primary" : "default" } onClick={() => handleUpdateStatus(props.report.quoteId, BTS_REPORT_STATUS.OPEN)}>Open</Button>
    <Button className={styles['pending-btn']} size="small" style={{width:"5rem", }} type={props.report.status === BTS_REPORT_STATUS.PENDING ? "primary" : "default" } onClick={() => handleUpdateStatus(props.report.quoteId, BTS_REPORT_STATUS.PENDING)}>Pending</Button>
    <Button className={styles['done-btn']} size="small" style={{width:"5rem", }} type={props.report.status === BTS_REPORT_STATUS.DONE ? "primary" :"default"  } onClick={() => handleUpdateStatus(props.report.quoteId, BTS_REPORT_STATUS.DONE)}>Done</Button>
  </Space>
  

  return <div className="site-layout-background">

    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      <Title level={2}>BTS Dashboard</Title>

      <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
        <div style={{width: "40rem"}}>
          <Input value={filter.search} onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search Trucks" allowClear />
        </div>
        <Button type="primary" loading={isExporting} onClick={() => handleExport(filter, sort)}>Export</Button>
      </div>

      <Collapse
        style={{ width: '100%'}} size="small"
        defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
        {...AdvancedSearchConfig}
        items={[{
          key: "advanced-search",
          label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
          forceRender: true,
          children:
            <Form
              initialValues={filterWithDateRange}
              form={filterForm} 
              onValuesChange={filterChange}
              layout="vertical"
              className="custom-form-item"
            >

            <Row>
              <Col xs={8} xxl={3}>
                <Form.Item
                  name="dateFilterType"
                  label="Date Filter"
                  rules={[{ validator: validateDateFilterType }]}
                >
                  <Select
                    options={Object.keys(DateFilterType)
                      .filter(t => t !== _.capitalize(DateFilterType.Approval))
                      .filter(t => t !== _.capitalize(DateFilterType.Creation))
                      .map(e => ({ label: _.capitalize(e), value: e }))}
                    allowClear
                  />
                </Form.Item>
              </Col>


              <Col xs={14} xxl={6}>
                <Form.Item
                  name="dateRange"
                  label="Date Filter Range"
                  rules={[{ validator: validateRangePicker }]}
                >
                  <RangePicker
                    name="range"
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>

            </Row>
            </Form>
        }]}
      />

      <Table
        rowKey={"id"}
        scroll={{ x: true }}
        style={{ width: "100%" }}
        bordered
        loading={btsReportLstAsync.isLoading() || selectedReportAsync.isLoading()}
        onChange={tableOnChange}
        pagination={pagination}
        dataSource={btsReportLst}
        expandable={{
          expandedRowRender: (q:BtsReport) => {
            const dealerCustomer = q.dealerCustomer;
            const customer = q.endCustomer;
            const items:DescriptionsItemType[] = [];

            if ( q.dealer ) {
              items.push( {
                span:1,
                label: "Dealer",
                children: <div>
                  <div>
                    <div>{q.dealer?.name}</div>
                    {!!dealerCustomer?.addressLine1 && <div>{dealerCustomer.addressLine1}</div>}
                    {!!dealerCustomer?.addressLine2 && <div>{dealerCustomer.addressLine2}</div>}
                    <div>{[[dealerCustomer?.city, dealerCustomer?.stateProvince].filter(v=>v).join(", "), dealerCustomer?.postalCode].filter(v=>v).join(" ")}</div>
                  </div>
                  <div>
                    {!!dealerCustomer?.contactName && <div>{[dealerCustomer.contactName, dealerCustomer.contactRole && ["(", dealerCustomer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                    {!!dealerCustomer?.contactPhone && <div>{dealerCustomer.contactPhone}</div>}
                    {!!dealerCustomer?.contactEmail && <div>{dealerCustomer.contactEmail}</div>}
                  </div>
                </div>
              });
            }
            if ( customer ) {
              items.push( {
                span:1,
                label: "End Customer",
                children: <div>
                  <div>
                    <div>{customer?.name}</div>
                    {!!customer?.addressLine1 && <div>{customer.addressLine1}</div>}
                    {!!customer?.addressLine2 && <div>{customer.addressLine2}</div>}
                    <div>{[[customer?.city, customer?.stateProvince].filter(v=>v).join(", "), customer?.postalCode].filter(v=>v).join(" ")}</div>
                  </div>
                  <div>
                    {!!customer?.contactName && <div>{[customer.contactName, customer.contactRole && ["(", customer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                    {!!customer?.contactPhone && <div>{customer.contactPhone}</div>}
                    {!!customer?.contactEmail && <div>{customer.contactEmail}</div>}
                  </div>
                </div>
              });
            }
            items.push(
              {
                span: 3,
                label: "Trucks",
                children: <Descriptions key={"truckInfo"} style={{width: "50rem"}} size="small" column={3}
                  items={q.trucks.map( (t) => [
                    { key:"vin-" + t.truckSerialNumberStr,            label: "VIN",                 children: t.vin },
                    { key:"productionDate-" + t.truckSerialNumberStr, label: "Est Production Date", children: (t.productionDate ? dayjs(t.productionDate).format("M/DD/YY") : "N/A") },
                    { key:"shipDate-" + t.truckSerialNumberStr,       label: "Est Ship Date",       children: (t.shipDate ? dayjs(t.shipDate).format("M/DD/YY") : "N/A") },
                  ]).flat()}
                />,
              }
            );


            return <>
              <div style={{display: "flex", gap: "10rem", marginBottom: "1rem" }}>
                <Descriptions key={"expandedInfo"} 
                  className={styles['expandedInfo-description']}
                  column={2}
                  layout="vertical" size="small" colon={false}
                  items={items}
                />
              </div>
            </>}
        }}
        columns={ [
          {
            title: "Description",
            fixed: "left",
            key: "latestRevision.summary.truckDescription",
            sorter: true,
            render: (q:BtsReport) => <><Link to={"/inventory/" + encodeURI(q.quoteId)} target="_blank"><div style={{width: "20rem"}}>{q.truckDescription}</div></Link>
            <Button onClick={() => handleShowComments(q)} 
              shape="circle" 
              type="text"
              icon={<Badge count={quoteCommentStats?.[q.quoteId]?.total} size="small" color={quoteCommentStats?.[q.quoteId]?.unread ? "red" : "grey" } >
              <InfoCircleTwoTone />
            </Badge>} />
            </>
            ,
          },
          {
            fixed: "left",
            key: "btsReportDetails.status",
            sorter: true,
            title: "Status",
            render: (q:BtsReport) => <BtsStatus report={q} />
          },
          {
            title: "First Production Date",
            key: "latestRevision.summary.firstTruckProductionDate",
            sorter: true,
            defaultSortOrder: "descend",
            render: (q:BtsReport) => q.firstTruckProductionDate && dayjs(q.firstTruckProductionDate).format("M/DD/YY"),
          },
          {
            title: "Next Production Date",
            key: "latestRevision.summary.nextTruckProductionDate",
            sorter: true,
            render: (q:BtsReport) => q.nextTruckProductionDate && dayjs(q.nextTruckProductionDate).format("M/DD/YY"),
          },
          {
            title: "First Ship Date",
            key: "latestRevision.summary.firstTruckShipDate",
            sorter: true,
            render: (q:BtsReport) => q.firstTruckShipDate && dayjs(q.firstTruckShipDate).format("M/DD/YY"),
          },
          {
            title: "Next Ship Date",
            key: "latestRevision.summary.nextTruckShipDate",
            sorter: true,
            render: (q:BtsReport) => q.nextTruckShipDate && dayjs(q.nextTruckShipDate).format("M/DD/YY"),
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
          },
          {
            title: "End Customer",
            key: "latestRevision.endCustomer.name",
            sorter: true,
            render: (q:BtsReport) => <>{q.endCustomer?.name}</>
          },
          {
            title: "Dealer",
            key: "dealer.name",
            sorter: true,
            render: (q:BtsReport) => q.dealer?.name,
          },
          {
            title: "Chassis",
            key: "latestRevision.model.name",
            sorter: true,
            render: (q:BtsReport) => [q.modelYear, q.chassis].join(" ")
          },
          {
            title: "Body Mfg",
            dataIndex: "bodyMfg",
          },
          {
            title: "Body Model",
            dataIndex: "bodyModel",
          },
          {
            title: "Fuel",
            dataIndex: "fuelType",
          },
        ]}
      />
    </Space>
    <CommentsDrawer report={selectedReport} 
      open={isCommentsOpen && !!selectedReport}
      onClose={() => {
        setIsCommentsOpen(false);
        updateQuoteCommmentCnt(selectedReport?.quoteId);
      }}
    />
  </div>
};


const CommentsDrawer = (props:DrawerProps & { report:BtsReport | undefined }) => {

  const { report, ...drawerProps } = props;
  
  return <>
    <Drawer
      {...drawerProps}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{report?.truckDescription} Comment(s)</div>
        </div>
      </>}
    >
      <CommentActivityList quoteId={report?.quoteId} {...props} topic={CommentTopic.BTS} />
    </Drawer>
  </>
}




export default BtsPage;
