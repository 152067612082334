import {Button, Col, Collapse, Descriptions, DescriptionsProps, Input, Modal, notification, Row, Spin, Switch, Timeline, Tooltip} from "antd";
import dayjs from 'dayjs'
import {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Quote, RevisionType, RevisionStatus, ParentQuoteHistoryDto, AssemblyInfo, RevisionApprovalStatus, ApprovalDiff, WorkflowStep, Revision} from "../../api/models";
import { ConfiguratorContext } from "../../context";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import { Utils } from "../../util/util";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import { HISTORY_PANEL_KEY } from "../../pages/configurator";
import WorkflowProgress from "../WorkflowProgress";
import { CopyOutlined, PartitionOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { config } from "process";

interface ApprovalChange {
  approvals: WorkflowStep[] | undefined,
  latestApproval: WorkflowStep | undefined,
  firstApproval: WorkflowStep | undefined,
  diff: ApprovalDiff | undefined,
  revision: Revision
  prevRevision?: Revision
}

const QuoteHistoryTab = (props: { 
  tabKey: string;
}) => {
  const { tabKey } = props;
  const configurator = useContext(ConfiguratorContext);

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const [approvalChangeHistory, setApprovalChangeHistory] = useState<ApprovalChange[]>();
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();
  const [filter, setFilter] = useState<string>();
  const [expandAll, setExpandAll] = useState<boolean>(false);

  useEffect(() => {
    if (tabKey === HISTORY_PANEL_KEY) {
      getHistory();
    }
  }, [tabKey]);

  const loadApprovalDiff = async (
    quoteIdA:string,
    revisionA:number,
    quoteIdB:string | undefined,
    revisionB:number | undefined,
  ): Promise<ApprovalDiff | undefined>  => {

    try {
      const resp = await configurator.api.diffArbitraryRevisions(quoteIdA, revisionA, quoteIdB, revisionB);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch approval differences. " + errorMsg });
    }
    return;
  }

  const getApprovalWorkflow = async (quoteRevisionId: number, ): Promise<WorkflowStep[] | undefined>  => {

    try {
      const resp = await configurator.api.getQuoteRevisionApprovalWorkflow( quoteRevisionId )
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch approval differences. " + errorMsg });
    }
    return;
  }


  const getHistory = async () => {

    if (!quote) return;

    const effectiveRevisions = quote?.revisions.filter(r => r.approvalStatus !== RevisionApprovalStatus.REJECTED);
    if (!effectiveRevisions.length) return;
    effectiveRevisions.sort( (a,b) => b.revision - a.revision );

    const history = await Promise.all(effectiveRevisions.map(async (a,ndx) => {

      const nextNdx = ndx + 1;
      const b = (nextNdx < effectiveRevisions.length ) ? effectiveRevisions[ nextNdx ] : undefined;
      const [ diff, approvals ] = await Promise.all([ 
        loadApprovalDiff( quote.quoteId, a.revision, (!!b?.revision ? quote.quoteId : undefined), b?.revision),
        getApprovalWorkflow( a.id )
      ]);
      const history:ApprovalChange = {
        approvals,
        latestApproval: [...(approvals || [])].reverse().find( a => !!a.approval ),
        firstApproval: approvals?.find( a => !!a.approval ),
        diff,
        revision: a,
        prevRevision: b
      }
      return history;
    }))


    setApprovalChangeHistory(history);
  }

  const getEventTitle = (change: ApprovalChange, idx: number) => {
    const id = (!!change.revision.reservation) ? "RESERVATION"
      : !!change.revision.revisionType ? `approvalType.${change.revision.revisionType}`
      : undefined;
    const approvalType = id ? intl.formatMessage({id}) : undefined;

    return (
    <div style={{marginBottom: "-2rem", marginTop: "-.7rem"}} key={`${idx}-panel`}>
      <span>Revision {change.revision.revision} { approvalType ? `- ${approvalType}`: undefined}</span>
      <Descriptions 
        layout="horizontal"
        items={getDescriptionItem(change)}
        style={{marginBottom: "1rem"}}
        column={5}
      />
    </div>
    );
  }

  const getPriceDiffStr = (change: ApprovalChange): string => {

    if (change.revision.revisionType === RevisionType.ENGINEERING_CHANGE) return "Price Protected";

    const priceDiff = change?.diff?.priceDiff;
    if (!priceDiff?.before || !priceDiff?.after) return "NA";
    const oldValue = priceDiff?.before.replace(/,/g, '');
    const newValue = priceDiff?.after.replace(/,/g, '');
    return Utils.formatMoneyWithSign(Number(newValue) - Number(oldValue));
  };

  const EngineeringStatus = (props:{ change: ApprovalChange}) => {
    const statusColors: Record<string, string> = {
      [RevisionStatus.APPROVED]: 'green',
      [RevisionStatus.REJECTED]: 'red',
      [RevisionStatus.PENDING_APPROVAL]: 'blue',
      [RevisionStatus.DRAFT]: 'blue',
    };
  
    const approvalStatus = props.change.revision.approvalStatus;
    if (!approvalStatus) return <p></p>;

    const color = statusColors[approvalStatus];

    if (!color) return <p></p>;
  
    const text = Utils.snakeCaseToFirstLetterCapitalized(props.change.revision.approvalStatus);
  
    return <p style={{ color, fontWeight: "bold" }}>{text}</p>;
  };
  

  const getDescriptionItem = (change: ApprovalChange): DescriptionsProps['items'] => {

    let items = [
      {
        key: "requestedBy",
        label: "Requested By",
        children: Utils.formatUsername(change.firstApproval?.approval?.requestedBy)
      },
      {
        key: "requestedAt",
        label: "Requested At",
        children: change.firstApproval?.approval?.createdAt ? dayjs( change.firstApproval.approval.createdAt ).format("M/DD/YY, h:mm a") : ''

      },
      {
        key: "epicorRev",
        label: "Epicor Rev",
        children: change.revision.erpRevisionNum ? String(change.revision.erpRevisionNum) : "-",
      },
      {
        key: "priceChange",
        label: "Total Price Change",
        children: getPriceDiffStr(change),
      },
    ];

    return items;
  } 

  const hidePrice = (change: ApprovalChange): boolean => {
    return change?.revision.revisionType === RevisionType.ENGINEERING_CHANGE || configurator.isDealerSales();
  }

  const HistoryItem = (props: {
    change: ApprovalChange, idx: number, filter?: string | undefined, expandAll?: boolean
  }) => {

    const { change, idx, filter, expandAll } = props;

    const key = "change-" + change.revision.revision + "-" + change.prevRevision?.revision
    const revisionType = change.revision.revisionType;
    const approvalStatus = change.revision.approvalStatus;

    let items = [
      {
        key: key,
        label: getEventTitle(change, idx),
        children: 
        <>
          {(revisionType === RevisionType.ENGINEERING_CHANGE || revisionType === RevisionType.CANCEL_ORDER) && 
            <>
              <EngineeringStatus change={change} />
              {change.latestApproval?.approval?.changeSummary && 
                <div key="changeSummary">
                  <Title level={5}>{`${change.revision.revisionType === RevisionType.ENGINEERING_CHANGE ? "Change Summary" : "Cancellation Message"}:` }</Title>
                  <p>{change.latestApproval?.approval.changeSummary}</p>
                </div>
              }
            </>
          }
          {(revisionType === RevisionType.CHANGE_ORDER) && 
            <>
              {(!change.approvals?.length && approvalStatus === RevisionApprovalStatus.DRAFT) && <p style={{color: 'blue', fontWeight: "bold"}}>{Utils.snakeCaseToFirstLetterCapitalized(approvalStatus)}</p>}
            </>
          }
          {revisionType === RevisionType.CHANGE_ORDER && 
            <>
              {change.revision.dealerRequest &&
                <div key="dealerRequest">
                  <Title level={5}>Dealer/Sales Change Request: </Title>
                  <p>{change.revision.dealerRequest}</p>
                </div>
              }
            </>
          }

          {!!change?.firstApproval?.approval?.pendingSplit?.partners?.length && 
            <Title level={5}>The other quote in this split change: 
              {change?.firstApproval?.approval?.pendingSplit.partners?.map(p => <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(p.quoteId)} target="_blank">{p.quoteId}</Link>)}
           </Title>}

          {change?.approvals && 
            <WorkflowProgress
              workflow={change?.approvals}
              screenChangeWidthPx={1300}
              reservation={change.revision.reservation}
            />}
          <br/>
          {change.diff && <ApprovalDiffTable diff={change.diff} hidePriceChange={hidePrice(change)} hideCustomOption={!filter} hideSummaryPrice={true} />}
        </>
      },
    ];

    return (
      <Collapse style={{marginBottom: ".6rem"}} key="history" className="history-collapse"  bordered={false} items={items} activeKey={expandAll ? key : undefined}/>
    );
  }

  const includesFilter = (text: string | undefined, filter: string) =>
    !!text?.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  
  const assembliesIncludeFilter = (assemblies: AssemblyInfo[] | undefined, filter: string) => 
    assemblies?.some(a => includesFilter(a.label, filter) || includesFilter(a.bomDescription, filter) || includesFilter(a.bom, filter));
  
  const filtering = (h: ApprovalChange) => {
    if (!filter) return true;
  
    const normalizedFilter = filter.replace(/ /g, "_").toLocaleLowerCase();
  
    return (
      includesFilter(h.revision.revisionType, normalizedFilter) ||
      includesFilter(h.revision.changeSummary, filter) ||
      includesFilter(h.revision.dealerRequest, filter) ||
      assembliesIncludeFilter(h.diff?.assembliesDiff?.addedAssemblies, filter) ||
      assembliesIncludeFilter(h.diff?.assembliesDiff?.removedAssemblies, filter)
    );
  };
  

  return (
    <>
      <style>
        {`
          .history-workflow ant-steps-item-content {
            text-wrap: wrap;
            max-width: 120px;
          }
          .history-collapse {
            border: 2px solid #1890ff;
            background-color: white;
          }
          .history-collapse .ant-collapse-header .ant-collapse-arrow svg {
            color: darkgrey;
            fill: #1677ff;
          }
        `}
      </style>
      {isAdminOrEngineeringOrSalesDesk && <Row justify={"space-between"}>
        <Col>
          {!!approvalChangeHistory?.length && 
            <Input key={"search-area"} style={{width: "350px"}} placeholder="Search change type, assembly, change summary..." onChange={(event) => {setFilter(event.target.value)}}/>
          }
        </Col>
        <Col>
          {!!approvalChangeHistory?.length && <Tooltip title="Expand All History Panels">
            <Switch checked={expandAll} style={{marginRight: "250px"}} onChange={setExpandAll} checkedChildren="Expanded" unCheckedChildren="Collapsed"/>
          </Tooltip>}
        </Col>
        <Col>
          <ParentQuoteHistory quote={quote} canAccess={!!isAdminOrEngineeringOrSalesDesk} style={{marginBottom: "1rem"}}/>
        </Col>
      </Row>}
      {!approvalChangeHistory && <Row justify={"center"}><Spin/></Row>}
      {Array.isArray(approvalChangeHistory) && !approvalChangeHistory.length && <Row justify={"center"}><Title level={5}>There's no history to this quote yet.</Title></Row>}

      {approvalChangeHistory?.filter(h => filtering(h)).map((change: ApprovalChange, idx: number) => 
        <div key={"div-" + idx}>
          <HistoryItem change={change} idx={idx} filter={filter} expandAll={expandAll} />
        </div>
      )}
    </>
  );
}

export default QuoteHistoryTab;

const ParentQuoteHistory = (props: {quote: Quote | undefined, canAccess: boolean,  style: any}) => {

  const {quote, canAccess, ...buttonProps} = props;
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState<boolean>(false);
  const [quoteHistory, setQuoteHistory] = useState<ParentQuoteHistoryDto[]>([]);
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();

  const getParentHistory = async () => {
    if (!quote?.id) return;
    setOpen(true);
    try {
      const resp = await configurator.api.getParentQuotes(quote.id);
      setQuoteHistory(resp.data);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get parent history " + errorMsg });
    }
  }

  const getItemTitle = (qh: ParentQuoteHistoryDto): ReactNode => {
    const quoteName = qh.quoteName + `, ${qh.quoteId} (Rev: ${qh.rev})`;
    const createdAt = qh.createdAt ? dayjs(qh.createdAt).format("MMM Do YYYY, hh:mm") : "";
    const quoteOwner = `At: ${createdAt}, By: ${qh.createdBy || ''}`
    return (
      <div key={qh.id + "-his"}>
        {isAdminOrEngineeringOrSalesDesk ? 
          <Link to={"/configurator/" + encodeURI(qh.quoteId)} target="_blank">
            {quoteName}
          </Link>
          :
          <div key={qh.id + "-child-detail"} style={{marginBottom: "2rem"}}>{quoteName}</div>
        }
        <div key={qh.id + "-child-owner"} style={{marginBottom: "2rem"}}>{quoteOwner}</div>
      </div>
      )
  }


  return (<>
      {canAccess && <Button {...buttonProps} type="primary" onClick={getParentHistory}>Get Parent History</Button>}
      <Modal
        title="Quote Parent History"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        {!!quoteHistory.length ? 
        <Timeline
          mode="left"
          style={{marginTop: "4rem", marginLeft: "-24rem"}}
          items={
            quoteHistory.map(qh => {return {
              key: qh.id,
              color: qh.type === "copy" ? "blue" : "green",
              label: qh.type.toLocaleUpperCase(),
              children: getItemTitle(qh),
              dot: qh.type === "copy" ? <CopyOutlined style={{fontSize: "20px"}}/> : <PartitionOutlined style={{fontSize: "20px"}}/>
            }})
          }
        />
      : <span>There's no parent history to this quote.</span>
      }
      </Modal>
  </>);
}


