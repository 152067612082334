import {Form, notification, FormProps, Input, } from "antd";
import {useContext, useEffect } from "react";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import { User } from "../../api/models";
import UserMultiSelector from "../UserMultiSelector";
import BMReadOnly from "../BMReadOnly";

export interface SalesTeamFormValues {
  id: number
  name?:string
  sales?:User[]
  engineers?:User[]
  support?:User[]
  viewers?:User[]
}

export const SalesTeamForm = (props:FormProps & {
  dealerId?: string
}) =>  {

  const {dealerId, ...formProps} = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  //todo load BM sales
  const [salesLst, salesLstAsync] = useAsyncState<User[]>();
  const [supportLst, supportLstAsync] = useAsyncState<User[]>();
  const [engineerLst, engineerLstAsync] = useAsyncState<User[]>();
  const [viewerLst, viewerLstAsync] = useAsyncState<User[]>();
  
  useEffect(() => {
    Promise.all([
      loadSales(), loadEngineer(), loadSupport(), loadViewers(),
    ]);
  }, [])

  useEffect(() => {
    props.form?.resetFields();
  }, [props.initialValues] );


  const loadSales = async () : Promise<User[] | undefined> => {

    salesLstAsync.setLoading()
    try {
      const resp = await configurator.api.fetchSales();
      salesLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load sales. " + errorMsg;
      notification.error( { message: msg });
      salesLstAsync.setFail(msg);
    }

    return;
  }

  const loadSupport = async () : Promise<User[] | undefined> => {

    supportLstAsync.setLoading()
    try {
      const resp = await configurator.api.fetchSupport();
      supportLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load support. " + errorMsg;
      notification.error( { message: msg });
      supportLstAsync.setFail(msg);
    }

    return;
  }

  const loadEngineer = async () : Promise<User[] | undefined> => {

    engineerLstAsync.setLoading()
    try {
      const resp = await configurator.api.fetchEngineers();
      engineerLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load engineers. " + errorMsg;
      notification.error( { message: msg });
      engineerLstAsync.setFail(msg);
    }

    return;
  }

  const loadViewers = async () : Promise<User[] | undefined> => {

    viewerLstAsync.setLoading()
    try {
      const resp = await configurator.api.fetchViewers(dealerId);
      viewerLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load viewers. " + errorMsg;
      notification.error( { message: msg });
      viewerLstAsync.setFail(msg);
    }

    return;
  }


  return <Form 
    {...formProps}
    layout="vertical"
  >
    <Form.Item name="id" 
      label="Team Id"
      hidden={true}>
      <Input />
    </Form.Item>

    <Form.Item name="name" 
      label="Team Name (optional)" >
      <Input />
    </Form.Item>

    <Form.Item name="sales" 
      label="Sales"
    >
      <UserMultiSelector userLst={salesLst}/>
    </Form.Item>


    <Form.Item name="engineers" 
      label="Engineering"
    >
      <BMReadOnly readOnly={configurator.isDealerSales()} 
        placeholder="None"
      >
      <UserMultiSelector userLst={engineerLst}/>
      </BMReadOnly>
    </Form.Item>


    <Form.Item name="support" 
      label="Support"
    >
      <BMReadOnly readOnly={configurator.isDealerSales() || !(configurator.isAdmin() || configurator.isSalesDesk() )} 
        placeholder="None"
      >
      <UserMultiSelector userLst={supportLst}/>
      </BMReadOnly>
    </Form.Item>

    <Form.Item name="viewers" 
      label="Viewers"
    >
      <UserMultiSelector userLst={viewerLst}/>
    </Form.Item>

    <div style={{fontStyle: "italic"}}>Note: The primary sales/support/engineer is listed first.</div>
  </Form>


}


export default SalesTeamForm;
