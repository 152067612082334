import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { AXIOS_CANCEL_MSG, User, DEFAULT_THROTTLE } from "../api/models";
import { notification } from "antd";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";
import { throttle } from "lodash";
import axios, {CancelTokenSource} from "axios";

export interface UsersContextType {
  userLstAsync?: AsyncState<User[]>
  loadUserList?:(modelId?:number)=>Promise<User[] | undefined>
}
const UsersContext = React.createContext<UsersContextType>({});

export const useUsersContext = () : UsersContextType => {
    return useContext(UsersContext);
}

// Data provider component
const UsersContextProvider = (props:{children: ReactElement | ReactElement[] }) => {

  const [_userLst, userLstAsync] = useAsyncState<User[]>([]);

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const context = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    reloadUserList();
  }, []);

  const reloadUserList = async () : Promise<User[] | undefined> => loadUserList(userLstAsync);
  
  const loadUserList = useCallback(throttle( async (userLstAsync:AsyncState<User[]>) : Promise<User[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    userLstAsync.setLoading();
    try {
      const resp = await context.api.fetchUserList();
      //most of the time we want them sorted by name
      const userLst = resp.data.sort((a, b) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ) );

      cancelTokenSourceRef.current = undefined;

      userLstAsync.setDone( userLst );
      return resp.data;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get userLst. " + errorMsg });
      userLstAsync.setFail(e.message);
    }
    return;
  }, DEFAULT_THROTTLE), []);

  const usersContext = useMemo<UsersContextType>( () => ({
    userLstAsync, 
    loadUserList:reloadUserList
  }) , [ userLstAsync ]);

  return <UsersContext.Provider value={usersContext}>{props.children}</UsersContext.Provider>;
};
  
export default UsersContextProvider;



