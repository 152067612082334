import React, { createContext } from "react";
import ConfiguratorAPI from "./api";
import {AsyncData} from "./api/async_data";
import { Approval, BaseCategory, CustomOptionType, Dealer, GlobalAccessRole, Permission, PricingSnapshot, Quote, User } from "./api/models";
import {AsyncState} from "./hook/useAsyncState";

export interface UserInfo {
    id: string
    name: string
    email: string
    roles: string[] //todo - user role enum
    permissions: string[]
    features: string[]
    dealerName?: string
}

export class Configurator {
    api: ConfiguratorAPI;
    userInfo?: UserInfo;
    pathHistory: string[];

  public hasRole(role:string | undefined) :boolean | undefined {
    if (!role) return;
    if ( !this.userInfo?.roles ) return;

    return [ ...this.userInfo.roles ].map(r => r.toUpperCase()).includes(role.toUpperCase());
  }

    public hasAnyPermission(permissions: Permission[]) :boolean {
        return permissions.some(p => this.hasPermission(p))
    }

    public hasPermission(permission: Permission) :boolean {
        return !!this.userInfo?.permissions?.includes(permission);
    }

    public isAdmin() :boolean {
        return !!this.userInfo?.roles.includes("admin");
    }

    public isDealerManagement() :boolean {
        return !!this.userInfo?.roles.includes("dealer_management");
    }

    public isReleaseEngineering() :boolean {
        return !!this.userInfo?.roles.includes("release_engineering");
    }

    public isEngineering() :boolean {
        return !!this.userInfo?.roles.includes("engineering");
    }

    public isEngineeringReadOnly() :boolean {
        return !!this.userInfo?.roles.includes("engineering_read_only");
    }

    public isSalesManager() :boolean {
        return !!this.userInfo?.roles.includes("sales_manager");
    }

    public isFinance() :boolean {
        return !!this.userInfo?.roles.includes("finance");
    }

    public isReporting() :boolean {
        return !!this.userInfo?.roles.includes("reporting");
    }

    public isSalesDesk() :boolean {
        return !!this.userInfo?.roles.includes("sales_desk");
    }

    public isProduction() :boolean {
        return !!this.userInfo?.roles.includes("production");
    }

    public isProcurement() :boolean {
        return !!this.userInfo?.roles.includes("procurement");
    }

    // Basic sales user
    public isInternalSales() :boolean {
        return !this.isDealerSales();
    }

    // Dealer user
    public isDealerSales() :boolean {
        return !!this.userInfo?.roles.includes("dealer");
    }

    public hasGlobalViewAccess() :boolean {
        return !!this.userInfo?.roles.some(role => Object.values(GlobalAccessRole).map(value => String(value)).includes(role));
    }

    public hasFeature(feature: string) :boolean {
        return this.userInfo?.features.indexOf(feature) != -1;
    }

    public isQuoteOnly() :boolean {
        return !!this.userInfo?.roles.includes("quote_only");
    }

    public isSourcewellManager() :boolean {
        return !!this.userInfo?.permissions.includes(Permission.SOURCEWELL_MANAGER);
    }

    public isBts() :boolean {
        return !!this.userInfo?.permissions.includes(Permission.BTS_READ);
    }

    public isLogistics() :boolean {
        return !!this.userInfo?.permissions.includes(Permission.LOGISTICS_READ);
    }

}

const initialContext = new Configurator()
initialContext.api = new ConfiguratorAPI(process.env.REACT_APP_API_URL || "");
initialContext.pathHistory = [];

export const ConfiguratorContext = React.createContext<Configurator>(initialContext);

export interface PricingSnapshotListContext {
  pricingSnapshotList: PricingSnapshot[] | undefined
  pricingSnapshotListAsync: AsyncData<PricingSnapshot[]>
  loadPricingSnapshotList:()=>void
}
export const PricingSnapshotsContext = React.createContext<PricingSnapshotListContext>({
  pricingSnapshotList: undefined,
  pricingSnapshotListAsync: new AsyncData<PricingSnapshot[]>(),
  loadPricingSnapshotList: ()=>{},
});

export interface ApprovalContextType {
  approvalCount?: number | undefined;
  quoteInApproval?: Quote;
  approval?: Approval;
  getApprovalsCount?: () => void;
}

export const ApprovalContext = createContext<ApprovalContextType>({
  approvalCount: undefined,
  quoteInApproval: undefined,
  approval: undefined,
  getApprovalsCount: () => {},
});

export interface ModelCategoryContextType {
  modelCategoriesAsync?: AsyncState<BaseCategory[]>
  loadModelCategories?:(modelId?:number)=>Promise<BaseCategory[] | undefined>
}

export interface DealerListContextType {
  dealerLstAsync?: AsyncState<Dealer[]>
  loadDealerList?:()=>Promise<Dealer[] | undefined>
}
export const DealerListContext = createContext<DealerListContextType>({});

export const S3UrlMapContext = React.createContext<S3UrlMapContextType>({});
export interface S3UrlMapContextType {
  s3UrlMapAsync?:AsyncState<Record<string,string>>
}

export interface CustomOptionsContextType {
  customOptionLstAsync?:AsyncState<CustomOptionType[]>
  loadCustomOptions?:()=>Promise<CustomOptionType[] | undefined>
}
export const CustomOptionsContext = createContext<CustomOptionsContextType>({});

export const ModelCategoryContext = createContext<ModelCategoryContextType>({});
