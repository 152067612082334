import { Table, TablePaginationConfig } from "antd";
import {  TableProps } from "antd/lib";
import _ from "lodash";

const SubPageTable = (props:Omit<TableProps, 'pagination'> & {
pagination:TablePaginationConfig, 
}) => {
  const { ...tableProps} = props;

  const handleChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    props.onChange?.(
      {...pagination, pageSize: props.pagination.pageSize },
      filters,
      sorter,
      extra);
  }

  let pageSize = props.pagination.pageSize || 1;
  let dataSource =  _.chunk( props.dataSource, pageSize ) as any[];
  let total = dataSource.length;

  if ( props.pagination.total ) {
    dataSource = [ props.dataSource || [] ];
    total = props.pagination.total || 1;
  }

  return <Table 
    {...tableProps} 
    dataSource={dataSource}
    onChange={handleChange}
    pagination={{
    ...tableProps.pagination,
    total,
    pageSize: 1
    }}
  />
}

export default SubPageTable;


