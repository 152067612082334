import { Button, Space, Typography } from "antd";
import { useContext } from "react";
import {useHistory} from "react-router-dom";
import ModelYearModal from "../components/EndOfYearReportModal";
import { ConfiguratorContext } from "../context";
const { Title } = Typography;

const Reporting = () => {

  const configurator = useContext(ConfiguratorContext);
  const history = useHistory()
  return (
    <>
      <style>
        {`
          .report-button {
            min-width: 350px;
            margin-bottom: 10px; 
            color: darkgrey;
            border: 2px solid darkgrey;
          }

          .report-button.ant-btn-primary:hover {
            border-color: #1890ff;
            color: #1890ff;
            font-weight: bold !important;
          }
        `}
      </style>
      <div className="site-layout-background">
        <Title level={2} style={{marginBottom: "2rem"}}>Reporting</Title>
        <Space direction="vertical" size={15}>
        <div key="download-open-orders">
          <Button className="report-button">
            <a href={process.env.REACT_APP_API_URL + "/v1/reporting/open-orders"}>
              Download Open Orders
            </a>
          </Button>
        </div>
        <div key="sales-order-report">
          <Button className="report-button" onClick={() => {history.push("/reports/salesOrdersReport")}}>
            Sales Orders Report
          </Button></div>
        <div key="approvals-report">
          <Button className="report-button" onClick={() => {history.push("/reports/approvalsReport")}}>
            Approvals Report
          </Button>
        </div>
        <div key="pricing-report">
          <Button className="report-button" onClick={() => {history.push("/reports/pricingReport")}}>
            Pricing Report
          </Button>
        </div>
        <div key="truck-report">
          <Button className="report-button" onClick={() => {history.push("/reports/truckReport")}}>
            Truck Report
          </Button>
        </div>
        <div key="download-end-of-year">
          <ModelYearModal />
        </div>
        <div key="download-velocity-dealer-report">
          <Button className="report-button" >
            <a href={`${configurator.api.baseUrl}/v1/reporting/dealerVehicleReport/${encodeURIComponent('D056')}/csv`}>
              Download Velocity Vehicle Report
            </a>
          </Button>
        </div>
        </Space>
      </div>
    </>
  );
};

export default Reporting;
