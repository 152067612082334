import { Input, notification, Table } from "antd";
import { Key, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { AXIOS_CANCEL_MSG, Dealer,  SalesTeam,  SortDirection } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {RowSelectMethod, } from "antd/es/table/interface";
import { debounce } from "lodash";
import axios, {CancelTokenSource} from "axios";

type DealerSort = SorterResult<Dealer> | SorterResult<Dealer>[]
type SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface RowSelection<T> { 
  selections: T[], 
  type:RowSelectMethod
}
interface DealerFilter {
  search?:string
  primarySalesId?: string

}


const DEFAULT_PAGE_SIZE = 5;

export type DealerSelection = RowSelection<string>;

const SelectDealerBySalesTeamList = (props:{
  primarySalesId: string | undefined
  onChange?:(s:DealerSelection) => void
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [dealerList, dealerListAsync] = useAsyncState<Dealer[]>([]);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [selected, setSelected] = useState<DealerSelection>();
  const [filter, setFilter] = useState<DealerFilter>();
  const [sort, setSort] = useState<SortResult<Dealer>>({
    columnKey: "name",
    order: "ascend"
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
    showLessItems: true
  });
  useEffect(() => {
    loadDealers(dealerListAsync, pagination, filter, sort);
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setFilter( { ...filter, primarySalesId: props.primarySalesId } );
  }, [props.primarySalesId]);

  const loadDealers = useCallback(debounce( async (dealerListAsync:AsyncState<Dealer[]>, pagination: TablePaginationConfig, filter: DealerFilter | undefined, sorter:DealerSort) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "name",
      direction: sorter.order == 'descend' ? 'desc' : 'asc' as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    dealerListAsync.setLoading();
    try {
      const resp = await configurator.api.fetchDealerList({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;
      dealerListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Dealers failed to load. " + errorMsg });
        dealerListAsync.setFail(e.message);
      }
    }
  }, 600), []);


  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: DealerSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const handleChangeSearch = (search:string) => {
    const nextFilter:DealerFilter = {
      ...filter,
      search
    };
    setFilter(nextFilter);
    loadDealers( dealerListAsync, pagination, nextFilter, sort );
  }

  const handleSelectRow = (selectedRowKeys: Key[], _selectedRows:Dealer[], info: { type:RowSelectMethod } ) => {

    const lst = selectedRowKeys as string[];
    const selected = {selections: lst, type:info.type};
    setSelected( selected );
    props.onChange?.( selected );
  }

  const getSelectedSalesTeam = (d:Dealer) : SalesTeam | undefined => d.salesTeams?.find( st => st.sales?.find(v=>v)?.id === props.primarySalesId )

  return <>
    <div style={{width: "100%"}}>
      <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" allowClear />
    </div>

    <style>
      {`
      .dialog-updateDealerLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-updateDealerLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-updateDealerLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table 
      className="dialog-updateDealerLst"
      columns={ [ 
        {
          title: "Dealer",
          key: "name",
          fixed: "left",
          sorter: true,
          render: (d) => d.name,
        },
        {
          title: "Sales",
          render: (d:Dealer) => getSelectedSalesTeam(d)?.sales?.map(u => u.name ).join(", ")
        },
        {
          title: "Engineers",
          render: (d:Dealer) => getSelectedSalesTeam(d)?.engineers?.map(u => u.name ).join(", ")
        },
        {
          title: "Support",
          render: (d:Dealer) => getSelectedSalesTeam(d)?.support?.map(u => u.name ).join(", ")
        },
        {
          title: "Viewers",
          render: (d:Dealer) => getSelectedSalesTeam(d)?.viewers?.map(u => u.name ).join(", ")
        },
      ]}
      rowKey="id"
      size="small"
      pagination={pagination}
      onChange={tableOnChange}
      loading={dealerListAsync?.isLoading()}
      dataSource={dealerList}
      scroll={{ x: true }}
      rowSelection={{
        type:  "checkbox",
        onChange: handleSelectRow,
        hideSelectAll: false,
        selectedRowKeys: selected?.selections,
      }}
    />
  </>
}

export default SelectDealerBySalesTeamList;
