import {Quote, Approval} from "../api/models";
import Utils from "../util/util";
import WorkflowProgress from "./WorkflowProgress";


const ApprovalTransitionInfo = (props: {
  approval: Approval | undefined,
  isSingleAction: boolean,
  quote: Quote | undefined,
  hideDescription?: boolean;
}) => {

  const {approval, isSingleAction} = props;

  return <div key="transition-info">
    <style>
      {`
        .one-line > span { 
          font-weight: 600;
          margin-left: .3rem;
        }
      `}
    </style>
    {!props.hideDescription && <>
      <div style={{marginBottom: "1rem"}}>
        <div >Notes:</div>
        <div style={{whiteSpace: "break-spaces"}}>{approval?.notes}</div>
      </div>

      <div className="one-line">{!isSingleAction ? 'Approval needed from:' : 'Action needed from: '}
        <span>{Utils.snakeCaseToFirstLetterCapitalized(approval?.approverRole)}</span>
      </div>
    </>}

    <WorkflowProgress
      workflow={approval?.workflow}
      hideDescription={props.hideDescription}
      reservation={approval?.quoteInfo.reservation}
    />
    
  </div>;
}

export default ApprovalTransitionInfo;

