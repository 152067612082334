import {Button, Input, InputProps, Popover} from "antd";
import React, {useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {TextAreaProps} from "antd/lib/input";

const TextAreaPopover = (props: Omit<TextAreaProps, 'onChange'> & {
    onChange?: (v:string | null | undefined) => void
} ) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputRef = useRef<any>(null);
    const btnStyle = {borderBottom: "1px solid black"};
    const [val, setVal] = useState<string>(props.value as string);

    const handleOpen = () => {
        setVal( props.value as string );
        setIsOpen(true);
    }
    const handleBlur = () => {

        setIsOpen(false);

        if ( val === props.value ) return;

        props.onChange?.(  val );
    }

    return <Popover
        trigger={"click"}
        afterOpenChange={(open) => {
            if( open ) {
                inputRef.current?.focus();
            }
        }}
        open={isOpen}
        content={<TextArea {...props}
            ref={inputRef}
            value={val}
            onChange={(e) => setVal(e.target.value)}
            onBlur={handleBlur}
            onPressEnter={() => inputRef.current?.blur()}
        /> }
        arrow={false}
    >
        {!!val?.length
            ? <Button type="text" onClick={handleOpen} title={val}>
                <span style={{...btnStyle, maxWidth:"5rem", overflow: "hidden", textOverflow: "ellipsis"}}>{val}</span>
            </Button>
            : <Button style={{backgroundColor: "rgba( 0, 0, 0, 0.05 )"}} shape="circle" type="text" onClick={handleOpen} /> }
    </Popover>;
}

export default TextAreaPopover;
