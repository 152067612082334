import { useEffect } from "react";
import { Select, SelectProps } from "antd";
import Utils from "../util/util";
import { useBaseCategoryContext } from "../context/BaseCategoryContext";

const CategorySelector = (props: SelectProps) => {

  const {categoriesAsync, loadCategories} = useBaseCategoryContext();

  useEffect(() => {
    if ( ( categoriesAsync?.isInitial() || categoriesAsync?.isFail() ) && !categoriesAsync.isLoading() ) {
      loadCategories?.();
    }
  }, [])

  return (
    <Select
      allowClear
      showSearch
      {...props}
      optionFilterProp="label"
      options={categoriesAsync?.val?.map(cat => ({value:cat.id, label: Utils.stripSortingPrefix(cat.name)}))}
    />
  );
};

export default CategorySelector;
