import "./App.css";
import { Affix, Col, ConfigProvider, Layout, notification, Row, Spin } from "antd";
import { ApprovalContext, ApprovalContextType, ConfiguratorContext, DealerListContext, PricingSnapshotListContext, PricingSnapshotsContext, UserInfo } from "./context";
import { useContext, useEffect, useMemo, useState } from "react";
import './Admin.css';
import {useAsyncState} from "./hook/useAsyncState";
import { Dealer, PricingSnapshot, User } from "./api/models"
import { useIntl } from "react-intl";
import SiteNav from "./components/SiteNav";
import SiteRouter from "./components/SiteRouter";
import useCheckMobileScreen from "./hook/useCheckMobileScreen";
import { useHistory, useLocation } from "react-router-dom";
import DrawerSiteNav from "./components/DrawerSiteNav";
import SourcewellCntProvider from "./contexts/SourcewellCntContext";
import CategoryContextProvider from "./contexts/CategoryContext";
import enUS from 'antd/lib/locale/en_US'
import VersionChecker from "./components/VersionChecker";
import { ResizableBox } from "react-resizable";
import UsersContextProvider from "./contexts/UsersContext";
import BaseCategoryContextProvider from "./context/BaseCategoryContext";

const { Content, Footer, Sider } = Layout;

const Admin = () => {
  const intl = useIntl();
  const [approvalsCount, setApprovalsCount] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const context = useContext(ConfiguratorContext);
  const locationContext = useLocation();
  const history = useHistory();
  const isMobile = useCheckMobileScreen();

  const [_dealerLst, dealerLstAsync] = useAsyncState<Dealer[]>([]);
  const loadDealerList = async () : Promise<Dealer[] | undefined> => {
    dealerLstAsync.isLoading()
    try {

      const lst = await context.api.getDealers();
      dealerLstAsync.setDone(lst);

      return lst;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get dealers. " + errorMsg });
      dealerLstAsync.setFail(e.message);
    }

    return;
  };

  const dealerListContext = useMemo(() => ({
    dealerLstAsync, loadDealerList
  }), [dealerLstAsync ]);


  //setup pricing snapshot context for global access to pricing snapshots
  const [pricingSnapshotList, pricingSnapshotListAsync] = useAsyncState<PricingSnapshot[]>();
  const pricingSnapshotsContext = useMemo<PricingSnapshotListContext>(() => ({ 
    pricingSnapshotList,
    pricingSnapshotListAsync:pricingSnapshotListAsync.getAsyncData(), 
    loadPricingSnapshotList: () => {

      //don't load if dealer
      if (context.isDealerSales()) {
        pricingSnapshotListAsync.setFail(intl.formatMessage({id: "quote.invalidAuth.loadPricing"}));
        return;
      }

      pricingSnapshotListAsync.setLoading();
      context.api.fetchPricingSnapshots()
      .then(resp => pricingSnapshotListAsync.setDone(resp.data),
            reason => pricingSnapshotListAsync.setFail(reason));
    }
  }), [ pricingSnapshotListAsync.state ]);

  const getApprovalQuantity = async () => {
      context.api.getApprovalCount()
      .then( resp => setApprovalsCount(resp?.data),
            (e) => console.log(e));
  }

  const approvalContext = useMemo<ApprovalContextType>( () => ({
      approvalCount: approvalsCount,
      getApprovalsCount: getApprovalQuantity
    }) , [ approvalsCount ]);

  useEffect(() => {
    loadUserInfo();
    getApprovalQuantity();
  }, []);

  const loadUserInfo = async () => {
    const info = await context.api.getUserInfo();
    context.userInfo = info;
    setUserInfo(info);
  };


  useEffect(() => {
    return history.listen((s)=>{
      context.pathHistory.push( s.pathname );
    })
  }, []);

  if (userInfo == null) {
    return (
      <div style={{height: "100vh", alignItems: "center", display: "grid"}}>
        <Spin tip="Loading" size="large" style={{scale: "1.2"}}>
          <div className="content" />
        </Spin>
      </div>
    );
  }

  const SIDER_WIDTH =  "275px";
  const hasChildSider = locationContext.pathname.startsWith("/configurator");

  return <>
    <VersionChecker />
    <ConfiguratorContext.Provider value={context}>
      <ConfigProvider locale={enUS}>
      <BaseCategoryContextProvider >
      <CategoryContextProvider >
      <UsersContextProvider >
      <PricingSnapshotsContext.Provider value={pricingSnapshotsContext}>
      <ApprovalContext.Provider value={approvalContext}>
      <DealerListContext.Provider value={dealerListContext}>
      <SourcewellCntProvider>

        <Layout>
          {(isMobile && !hasChildSider )
            ?  <DrawerSiteNav />
            :  <FixedSiteNav hasChildSider={hasChildSider} width={SIDER_WIDTH} />
          }
          <Layout className="site-layout" 
            style={{ marginLeft: 0, zIndex: 2 }}
          >
          {process.env.REACT_APP_PRODUCTION_ENV !== 'true' && !hasChildSider && 
              <Affix style={{  textAlign: "center", backgroundColor: "rgba(0, 0, 0, 0)"}}>
                <div style={{backgroundColor: "yellow",display: "inline-block", padding: ".4rem"}}> This is a non-production environment. </div>
              </Affix>
          }
          <Row
            justify="center"
            style={{ minHeight: "100vh", marginBottom: "-70px" }}
          >
            <Col span={24}>
              <Content>
                <SiteRouter />
              </Content>
            </Col>
          </Row>
          <Footer
            style={{
              height: "70px",
              width: "100%",
              bottom: 0,
              textAlign: "center",
              zIndex:1, 
              marginTop: '70px'
            }}
          >
            © {new Date().getFullYear()} Battle Motors
          </Footer>
        </Layout>
      </Layout>

      </SourcewellCntProvider>
      </DealerListContext.Provider>
      </ApprovalContext.Provider>
      </PricingSnapshotsContext.Provider>
      </UsersContextProvider>
      </CategoryContextProvider>
      </BaseCategoryContextProvider>
      </ConfigProvider>
    </ConfiguratorContext.Provider>
  </>

};

const FixedSiteNav = (props: { hasChildSider: boolean; width: number | string }) => {
  const { hasChildSider, width } = props;

  const [siderWidth, setSiderWidth] = useState<number>(
    typeof width === "number" ? width : 275
  );

  const handleResize = (_event: React.SyntheticEvent, { size }: { size: { width: number } }) => {
    setSiderWidth(size.width);
  };

  useEffect(() => {
    setSiderWidth(typeof width === "number" ? width : 275);
  }, [width]);

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <style>
        {`
        .react-resizable {
          box-sizing: border-box;
        }

        .react-resizable-handle {
          position: absolute;
          width: 10px;
          height: 100%;
          right: 0;
          top: 0;
          cursor: ew-resize;
          background: transparent !important;
          z-index: 2; /* Ensure the handle is above other elements */
        }

        .react-resizable-handle::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: transparent !important;
        }
        `}
      </style>
      <ResizableBox
        width={siderWidth}
        height={Infinity}
        axis="x"
        resizeHandles={["e"]}
        minConstraints={[200, 0]}
        maxConstraints={[500, 0]}
        onResizeStop={handleResize}
        handle={
          <span
            className="react-resizable-handle"
            style={{
              position: "absolute",
              width: "10px",
              height: "100%",
              right: 0,
              top: 0,
              cursor: "ew-resize",
              background: "rgba(0,0,0,0.1)",
            }}
          />
        }
      >
        <Sider
          style={{
            display: hasChildSider ? "none" : "block",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            boxSizing: "border-box", // Ensure consistent sizing
          }}
          width={siderWidth}
        >
          <SiteNav />
        </Sider>
      </ResizableBox>
    </div>
  );
};


export default Admin;
